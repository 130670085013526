<template>

    <html>
    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="author" content="Theme Region">
        <meta name="description" content="">
        <title>Business Registration</title>
        <!-- Bootstrap CSS -->
        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">
        <link href="css/animate/animate.min.css" rel="stylesheet">
        <link rel="stylesheet" href="css/bootstrap.css">
        <!--	bootstrap css-->
        <!--  CSS -->
        <link rel="stylesheet" href="css/navigation-temp.css">
        <link rel="stylesheet" href="css/style.css">
        <!-- <link rel="stylesheet" href="css/style2.css"> -->

        <link rel="stylesheet" href="css/form.css">
    </head>

    <body>
    <!--	!--	MENU CONTROLS-->

    <!-- header -->
    <!--		navigation bar -->

    <ToolBarMain/>

    <!--	search receipt pop up-->
    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="search-receipt">
        <div class="container p-lg-5 p-md-0">
            <div class="row p-5">
                <div class="receipt-search-container m-5 d-flex flex-column-md animated" id="get-receipt-container">
                    <div class="col-lg-6 receipt-img-container">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">print a receipt for a transaction</h2>
                            <p class="font-14 mb-3 text-capitalize">Make sure all inputs are correct. The process has been made this way to help protect personal data</p>
                        </div>
                    </div>
                    <div class="col-lg-6 p-5 position-relative"> <span class="close-receipt-form position-absolute"> <i data-feather="x"></i></span>
                        <form class="transaction-info">
                            <div class="">
                                <h4 class="mb-5 pb-2 text-capitalize">correctly fill in the form bellow to continue</h4>
                            </div>
                            <div class="row m-0">
                                <div  class="form-group col-sm-12 pl-md-0">
                                    <label for="sel1"><small>What was the transacton type?</small></label>
                                    <select class="form-control mb-3" id="sel1" required>
                                        <option>Parking</option>
                                        <option>Business</option>
                                        <option class="text-capitalize">County bills</option>
                                        <option class="text-capitalize">land rates</option>
                                        <option class="text-capitalize">rents</option>
                                        <option class="text-capitalize">e-Construction</option>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div class="row radio-container m-0">
                                <div class="col-12 pl-md-0 mb-3">
                                    <div class="form-check-inline">
                                        <label class="form-check-label">M-Pesa
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">NBK
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="exampleInputEmail1" class="text-capitalize"><small>what was the refference number for the transaction?</small></label>
                                    <input type="text" class="form-control" id="fname" placeholder="eg M-Pesa ref number" required>
                                </div>
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="identifier" class="text-capitalize"><small>Unique identifire for transaction eg Number plate, bill number, Business ID etc</small></label>
                                    <input type="text" class="form-control" id="identifier" placeholder="identifier" required>
                                </div>
                                <button type="submit" class="btn btn-primary text-capitalize text-white font-14 submit-btn">get receipt</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--form section-->
    <!--	pay confirmation pop up-->
    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="pay-confirmation-pop">
        <div class="container p-md-5 p-sm-0">
            <div class="row p-5 d-flex justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-5 payment-pop-container m-5 m-sm-3 d-flex flex-column-md animated p-0">

                    <div class="col-12 position-relative p-0">
                        <span class="close-receipt-form position-absolute z-index-1 d-none transactions-actions animated text-white" id="close-pricepop"> <i data-feather="x"></i></span>

                        <div class="">
                            <div class="show-amount-loader">
                                <center class="p-5 text-white">
                                    <div class="lds-roller animated"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <div class="confirmed-pay d-none animated"><img src="img/icons/transactions-icons/check.svg"></div>
                                    <p class="text-center text-white m-0 p-0 mb-3  payment-status">pending daily parking payment</p>
                                    <h2 class="mb-5 pb-5 text-capitalize text-white"><sup class="font-14 text-uppercase">Kes</sup>2,000</h2>
                                </center>
                            </div>
                            <div class="col-12 p-lg-5 p-sm-3 ">
                                <div class="col-12 p-0 transacton-instructions">
                                    <h5><strong class="text-capitalize pb-3">payment procedure</strong></h5>
                                    <p class="font-12">follow the following payment procedure in order to complete the payment</p>

                                    <hr>
                                    <ul type="1" class="font-14 pb-5">
                                        <li>1.Check on a <strong>payment pop </strong>up on your phone.</li>
                                        <li>2.Input your <strong>M-pesa pin</strong> and click OK.</li>
                                        <li>3.An <strong>MPESA</strong> confimation SMS will be sent to you.</li>
                                        <li>4.Wait for upto <strong>45 secs</strong> as we process your transaction</li>
                                    </ul>
                                </div>


                                <div class="col-12 p-0 pb-3 d-none transactions-actions animated mt-5">
                                    <h5><strong class="text-capitalize pb-3">Thank You!</strong></h5>
                                    <p class="font-14 text-capitalize m-0">Payment was succesfully received.</p>
                                    <p class="font-14">you can close the pop up or perform the actions bellow</p>
                                    <a href="#" class="btn btn-primary px-5 py-3 text-white text-uppercase font-12 font-sm-10 px-sm-4">Print Receipts</a>
                                    <a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase font-12 font-sm-10 px-sm-4">add to assets</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!--	pay confirmation pop up-->
    <section id="service-form-section" class="parallax-section">
        <div class="container">
            <div class="row p-5 ">
                <div class="service-form-container  flex-column-md animated col-12 p-0">
                    <div class="col-12 service-form-img-container" id="biz-img" style="height: 169px;">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">Business registration</h2>
                            <p class="font-14 mb-3 text-capitalize">fill in and remember to double check details to avoid any wrong submissions</p>
                        </div>
                    </div>
                    <div class="col-12 p-5 position-relative transactions-form-container d-flex">
                        <div class="col-12 p-0 the-transaction-form animated">
                            <div class="d-flex flex-column">
                                <div id="point-indicator" class="point-indicator  mb-4">
                                    <div class="justify-content-center d-flex">
                                        <span class="start-clickable active"></span>
                                        <span class="mx-3"></span>
                                        <span class="mr-3"></span>
                                        <span class=""></span>
                                    </div>
                                </div>
                                <hr>

                            </div>
                            <form class="transaction-form">

                                <!-- business information -->
                                <div v-if="bizinfo" class="row biz-info animated">
                                    <div class="col-12">
                                        <p class="mb-3 text-capitalize"><strong>business information</strong></p>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-6 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">business name</label>
                                        <input v-model="trade.businessDetails.businessName" type="text" class="form-control" id="plot" placeholder="Busness name" required>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-6 mt-2">
                                        <label for="reg-cert" class="text-capitalize">registration certificate No.</label>
                                        <input  type="text" class="form-control" id="plot" placeholder="certificate number" required>
                                    </div>


                                    <div class="form-group col-md-12 col-lg-6 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">KRA pin number</label>
                                        <input v-model="trade.businessDetails.kraPin" type="text" class="form-control" id="plot" placeholder="KRA pin" required>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-6 mt-2">
                                        <label for="reg-cert" class="text-capitalize">VAT number</label>
                                        <input  type="text" class="form-control" id="plot" placeholder="Vat No." required>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-6 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">P.O. BOX</label>
                                        <input v-model="trade.businessDetails.postalCode" type="text" class="form-control" id="plot" placeholder="Box Number" required>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-6 mt-2">
                                        <label for="zone" class="text-capitalize">postal Address</label>

                                        <input v-model="trade.businessDetails.postalAddress" type="text" class="form-control" id="plot" placeholder="Address" required>
                                    </div>

                                    <div class="col-sm-12 pt-3">
                                        <span @click="bizinfo_hide" type="submit" class="btn btn-primary text-white font-12 border-0 text-capitalize next-btn">next</span>

                                    </div>

                                </div>
                                <!--location and contacts -->
                                <div v-if="locationinfo" class="row location-info animated">
                                    <div class="col-12">
                                        <p class="mb-3 text-capitalize"><strong>Location and Contacts</strong></p>
                                    </div>
                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">mobile number</label>
                                        <input v-model="trade.businessDetails.businessPhone" type="text" class="form-control" id="plot" placeholder="ie 07..........">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="reg-cert" class="text-capitalize">other mobile number</label>
                                        <input v-model="trade.businessDetails.contactPersonPhone" type="text" class="form-control" id="plot" placeholder="ie 07..........">
                                    </div>


                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">FAX</label>
                                        <input  type="text" class="form-control" id="plot" placeholder="your Fax">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">Email</label>
                                        <input v-model="trade.businessDetails.businessEmail" type="text" class="form-control" id="plot" placeholder="I.E youuremail@email.com">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="reg-cert" class="text-capitalize">physical address</label>
                                        <input v-model="trade.businessDetails.physicalAddress" type="text" class="form-control" id="plot" placeholder="business location address">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">plot number</label>
                                        <input v-model="trade.businessDetails.plotNumber" type="text" class="form-control" id="plot" placeholder="plot number">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">building name</label>
                                        <input v-model="trade.businessDetails.businessName" type="text" class="form-control" id="plot" placeholder="Building name">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="zone" class="text-capitalize">building type</label>

                                        <select v-model="trade.businessDetails.floorNo" class="form-control bg-white" title="Select ward" data-live-search="true" >
                                            <option v-for="(item, index) in trade.floors" :value="item.floor" :key="index">
                                                {{ item.floor }}
                                            </option>
                                        </select>


                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">room/ Stall numberX</label>
                                        <input v-model="trade.businessDetails.roomNo" type="text" class="form-control" id="plot" placeholder="Box Number">
                                    </div>

                                    <div class="col-sm-12 pt-3 d-flex justify-content-between" id="location-btns">
                                        <span @click="bizinfo_hide2" type="submit" class="btn btn-white font-12 text-primary border-2 text-capitalize text-prev">previous</span>
                                        <span @click="location_hide" type="submit" id="location-next" class="btn btn-primary text-white font-12 border-0 text-capitalize">next</span>
                                    </div>

                                </div>
                                <!--owners information-->
                                <div v-if="ownerinfo" class="row owners-info animated">
                                    <div class="col-12">
                                        <p class="mb-3 text-capitalize"><strong>owners information</strong></p>
                                    </div>
                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">Owner Names</label>
                                        <input v-model="trade.businessDetails.fullNames" type="text" class="form-control" id="plot" placeholder="fname">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="reg-cert" class="text-capitalize">Owner's ID</label>
                                        <input v-model="trade.businessDetails.ownerID" type="text" class="form-control" id="plot" placeholder="Owner ID">
                                    </div>


                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">Owner's Email</label>
                                        <input v-model="trade.businessDetails.ownerEmail" type="text" class="form-control" id="plot" placeholder="Owner Email">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">Owner's Phone</label>
                                        <input  v-model="trade.businessDetails.ownerPhone" type="text" class="form-control" id="plot" placeholder="Owner's phone">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="reg-cert" class="text-capitalize">Contact Person Names</label>
                                        <input v-model="trade.businessDetails.contactPersonNames" type="text" class="form-control" id="plot" placeholder="Contact Person Names">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">Contact Person Phone </label>
                                        <input  v-model="trade.businessDetails.contactPersonPhone" type="text" class="form-control" id="plot" placeholder="Contact Person Phone">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">Contact Person IDNo</label>
                                        <input v-model="trade.businessDetails.contactPersonIDNo" type="text" class="form-control" id="plot" placeholder="Contact Person IDNo">
                                    </div>
                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">Contact Person Email</label>
                                        <input v-model="trade.businessDetails.contactPersonEmail"  type="text" class="form-control" id="plot" placeholder="Contact Person Email">
                                    </div>


                                    <div class="col-sm-12 pt-3 d-flex justify-content-between" id="owner-btns">
                                        <span @click="owner_hide2" type="submit" class="btn btn-white font-12 text-primary border-2 text-capitalize text-prev">previous</span>
                                        <span @click="owner_hide" type="submit" id="owners-next" class="btn btn-primary text-white font-12 border-0 text-capitalize">next</span>
                                    </div>

                                </div>
                                <!--activity information-->
                                <div v-if="activityinfo" class="row activity-info animated">
                                    <div class="col-12">
                                        <p class="mb-3 text-capitalize"><strong>activity information</strong></p>
                                    </div>
                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">activity description</label>
                                        <input  v-model="trade.businessDetails.businessDes" type="text" class="form-control" id="plot" placeholder="activity description">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="reg-cert" class="text-capitalize">area (M<sup>2</sup>)</label>
                                        <input v-model="trade.businessDetails.premiseSize" type="text" class="form-control" id="plot" placeholder="0.00">
                                    </div>


                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">other details</label>
                                        <input v-model="trade.businessDetails.buildingOccupancy" type="text" class="form-control" id="plot" placeholder="other ...">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="mpesa-phone" class="text-capitalize">number of employees</label>
                                        <input v-model="trade.businessDetails.numberOfEmployees" type="text" class="form-control" id="plot" placeholder="Number Of Employees">
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="zone" class="text-capitalize">sub county</label>
                                        <select v-model="subCounty" class="form-control  bg-white" title="Select Sub County" data-live-search="true" >
                                            <option v-for="(item, index) in subCounties" :value="item" :key="index">
                                                {{ item.subCountyName }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-4 mt-2">
                                        <label for="zone" class="text-capitalize">ward</label>
                                        <select v-model="ward" class="form-control bg-white" title="Select ward" data-live-search="true" >
                                            <option v-for="(item, index) in wards" :value="item" :key="index">
                                                {{ item.wardName }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="form-group col-md-12 col-lg-6 mt-2">
                                        <label for="zone" class="text-capitalize">activity description</label>
                                        <select @change="getTradeSubCategory($event)" class="form-control bg-white" title="Select Business Activity" data-live-search="true" >
                                            <option value=" ">Select Options</option>
                                            <option v-for="(item, index) in trade.tradeCategories" :value="item.incomeTypeId" :key="index">
                                                {{ item.incomeTypeDescription }}
                                            </option>
                                        </select>

                                    </div>

                                    <div class="form-group col-md-12 col-lg-6 mt-2">
                                        <label for="zone" class="text-capitalize">sub categories & business permit fee</label>
                                        <select @change="selectedTradeSubCategory($event)" class="form-control bg-white" title="Select Sub Categories" data-live-search="true" >
                                            <option v-for="(item, index) in trade.tradeSubCategories" :value="JSON.stringify(item)" :key="index">
                                                {{ item.feeDescription }} KES {{item.unitFeeAmount}}
                                            </option>
                                        </select>
                                    </div>


                                    <div id="activity-btns" class="col-sm-12 pt-3 d-flex justify-content-between">
                                        <span @click="activity_hide" type="submit" class="btn btn-white font-12 text-primary border-2 text-capitalize text-prev">previous</span>
                                        <button @click.prevent="registerBusiness()" type="submit" id="activity-next" class="btn btn-primary text-white foPPRnt-12 border-0 text-capitalize">finish</button>
                                        <div v-if="loading" class="lds-ellipsis animated"><div></div><div></div><div></div><div></div></div>

                                    </div>

                                </div>

                            </form>
                        </div>

                        <div class="col-12 d-none p-0 animated details-confirm">
                            <div class="">
                                <p class="mb-5 pb-2 text-capitalize">
                                    <span class="back-toform" title="back to transactions form"><i data-feather="arrow-left"  class="mr-3 float-left"></i></span><strong>confirm your transaction details</strong>
                                </p>
                                <hr class="mt-1 p-0">
                                <p><strong class="text-capitalize">transaction details</strong></p>

                                <div class="transactions-details-container text-capitalize">
                                    <p class="m-0"><span>business name</span></p>
                                    <strong>business name</strong>
                                    <hr>
                                    <p class="m-0"><span>business category</span></p>
                                    <strong>selected category comes here</strong>
                                    <hr>

                                    <p class="m-0"> <span>KRA PIN</span></p>
                                    <strong class="text-uppercase">Entered KRA pin</strong>
                                    <hr>
                                    <div class=" text-right amount-container">
                                        <p><span class="text-uppercase text-left">sub total</span> <strong> KES 2,000</strong></p>
                                    </div>
                                    <hr>

                                    <form>

                                        <div class="form-group col-sm-12 col-md-12 p-0">
                                            <label for="number-plate" class="text-capitalize">M-pesa phone number</label>
                                            <input type="text" class="form-control" id="number-plate" placeholder="eg 0712...">
                                        </div>

                                        <div class="col-12 p-0 text-uppercase mt-5">
                                            <button type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0 confirm-btn">
                                                <div class="btn-txt animated">
                                                    <span class="btn-text text-uppercase font-12">pay kes 2,000</span>
                                                    <i data-feather="arrow-right" class="ml-2 float-right pull-right">
                                                    </i>
                                                </div>
                                                <div class="lds-ellipsis d-none animated"><div></div><div></div><div></div><div></div></div>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--form section-->
    <!--			footer-->
    <FooterBar/>
    <!--			footer-->

    </body>
    </html>

</template>

<script>
    /* eslint-disable */
    import $ from "jquery";
    import ScrollMagic from "scrollmagic";
    import {biller,trade, execute, execute2, parking, paysol} from "@/api";
    import ToolBarMain from "@/components/ToolBar.vue";
    import FooterBar from "@/components/Footer.vue";


    export default {
        name: "CreateBusiness",
      components: {FooterBar, ToolBarMain},
        data() {
            return{
                loading: false,
                bizinfo: true,
                locationinfo: false,
                ownerinfo: false,
                activityinfo: false,

                hasReloaded: false,
                //Trade
                amount:0,
                unitFeeAmount:0,
                wardID:'',
                subCountyID:'',
                subCounties:[{
                    subCountyID: "",
                    subCountyName: ""
                }],
                wards:[{
                    wardID: "",
                    wardName: ""
                }],
                businessID:null,
                subCounty:{},
                ward:{},
                trade:{
                    amount: null,
                    tonnage:[],
                    floors:[],
                    tradeCategories:[ {
                        incomeTypeId: null,
                        incomeTypeDescription: null,
                        incomeTypePrefix: null,
                        fundDescription: null,
                        status: null,
                        departmentID: null,
                        zoneCategoryID: null,
                        zoneCategory: null
                    }],
                    tradeSubCategories:[
                        {
                            feeId: null,
                            feeDescription: null,
                            unitOfMeasure: null,
                            unitFeeAmount: null,
                            accountNo: null,
                            incomeTypeId: null,
                            feeType: null,
                            accountDesc: null,
                            costCenterNo: null,
                            zone: null,
                            typeDescription: null,
                            prorated: null,
                            duration: null
                        }
                    ],
                    businessDetails:{
                        id: null,
                        businessID: null,
                        businessName: null,
                        subCountyID: null,
                        subCountyName: null,
                        wardID: null,
                        wardName: null,
                        plotNumber: null,
                        physicalAddress: null,
                        buildingName: null,
                        buildingOccupancy: null,
                        floorNo: null,
                        roomNo: null,
                        premiseSize: null,
                        numberOfEmployees: null,
                        tonnage : null,
                        businessDes: null,
                        businessCategory: null,
                        businessSubCategory : null,
                        incomeTypeID : null,
                        feeID : null,
                        businessEmail: null,
                        postalAddress : null,
                        postalCode: null,
                        businessPhone: null,
                        contactPersonNames: null,
                        contactPersonIDNo: null,
                        businessRole: null,
                        contactPersonPhone: null,
                        contactPersonEmail: null,
                        fullNames: null,
                        ownerID: null,
                        ownerPhone: null,
                        ownerEmail: null,
                        kraPin: null,
                        createdBy: null,
                        createdByIDNo: null,
                        dateCreated: null,
                        lat : 0.0,
                        lng : 0.0
                    }
                },
                streams:[
                    {
                        id: null,
                        incomeTypePrefix: null,
                        stream: null
                    },
                ],

            }
        },

        mounted: function () {
            this.getTradeCategory()
            this.getFloor()
            this.getSubCounties()
        },
        watch:{
            incomeType: function () {
                this.getFeesAndCharges()
            } ,
            subCounty: function () {
                //Trade demographics
                this.trade.businessDetails.subCountyName = this.subCounty.subCountyName
                this.trade.businessDetails.subCountyID = this.subCounty.subCountyID

                this.getWards()
            },
            ward: function () {
                //Trade demographics
                this.trade.businessDetails.wardName = this.ward.wardName
                this.trade.businessDetails.wardID = this.ward.wardID

            }
        },
        methods:{
            bizinfo_hide() {

                this.bizinfo = false
                this.locationinfo = true
                this.ownerinfo = false
                this.activityinfo = false

            },
            location_hide() {
                this.bizinfo = false
                this.locationinfo = false
                this.ownerinfo = true
                this.activityinfo = false


            },
            owner_hide() {
                this.bizinfo = false
                this.locationinfo = false
                this.ownerinfo = false
                this.activityinfo = true


            },
            bizinfo_hide2() {
                this.bizinfo = true
                this.locationinfo = false
                this.ownerinfo = false
                this.activityinfo = false
            },
            owner_hide2() {
                this.bizinfo = false
                this.locationinfo = true
                this.ownerinfo = false
                this.activityinfo = false

            },
            activity_hide() {
                this.bizinfo = false
                this.locationinfo = false
                this.ownerinfo = true
                this.activityinfo = false

            },
            handlePointIndicatorClick(event) {
                if (event.target && event.target.matches('.location-clickable')) {
                    this.location_hide();
                } else if (event.target && event.target.matches('.info-clickable')) {
                    this.bizinfo_hide();
                }
            },

            //TRADE
            selectedFeeAndCharge(event){
                const data = JSON.parse(event.target.value)
                this.amount = data.unitFeeAmount
                this.feeID = data.feeId
                this.unitFeeAmount = data.unitFeeAmount
                this.feesAndChargeItems = []
                this.feesAndChargeItems.push(data)
            },
            registerBusiness(){
                this.loading = true
                this.trade.businessDetails.createdByIDNo = sessionStorage.getItem("idNo")
                this.trade.businessDetails.createdBy = sessionStorage.getItem("names")

                const data = new FormData();
                data.append("function", "registerBusiness");
                data.append("business",  JSON.stringify(this.trade.businessDetails));
                execute(data,trade)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {

                            this.businessID = res.data.data.business.businessID;
                            localStorage['params'] = JSON.stringify({
                                businessID: res.data.data.business.businessID
                            });
                            this.$router.push({ name: 'business-payment', params: { businessID: res.data.data.business.businessID } });

                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getFloor(){
                const data = new FormData();
                data.append("function", "getFloor");
                execute(data,trade)
                    .then((res) =>{
                        if (res.data.success) {
                            this.trade.floors =res.data.data.floors
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            gotTo(route){
                sessionStorage.setItem("selectedSub",route)
            },
            getTonnage(){
                const data = new FormData();
                data.append("function", "getTonnage");
                execute(data,trade)
                    .then((res) =>{
                        if (res.data.success) {
                            this.trade.tonnage =res.data.data.tonnage
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getTradeCategory(){
                this.message =null
                const data = new FormData();
                data.append( "function", "getIncomeTypes");
                data.append( "incomeTypePrefix", "SBP");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.trade.tradeCategories =res.data.data.incomeTypes
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getYears(){
                const data = new FormData();
                data.append("function", "getYears");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.years =res.data.data.years
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getTradeSubCategory(){
                this.message =null
                const data = new FormData();
                this.trade.businessDetails.incomeTypeID = event.target.value
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId", event.target.value);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.trade.tradeSubCategories =res.data.data.feesAndCharges
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            selectedTradeSubCategory(event){
                //set fee and charges
                this.selectedFeeAndCharge(event)

                const data = JSON.parse(event.target.value)
                this.trade.businessDetails.businessCategory = data.accountDesc
                this.trade.businessDetails.businessSubCategory =  data.feeDescription
                this.trade.amount =  data.unitFeeAmount
                this.trade.businessDetails.feeID = data.feeId;

            },
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounty");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties =res.data.data.subCounties
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getWards(){
                this.wards =[]
                this.ward = ''
                const data = new FormData();
                data.append("function", "getWards");
                data.append("subCountyID", this.subCounty.subCountyID);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.wards =res.data.data.wards
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },

        }
    }
</script>

<style scoped>

</style>