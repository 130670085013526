<template>
    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="author" content="Theme Region">
        <meta name="description" content="">
        <title>Renew Business  Permit</title>
        <!-- Bootstrap CSS -->

        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">
        <link href="css/animate/animate.min.css" rel="stylesheet">
        <link rel="stylesheet" href="css/bootstrap.css">
        <!--	bootstrap css-->
        <!--  CSS -->
        <link rel="stylesheet" href="css/navigation-temp.css">
        <link rel="stylesheet" href="css/style.css">
        <!-- <link rel="stylesheet" href="css/style2.css"> -->

        <link rel="stylesheet" href="css/form.css">
    </head>

    <body>
    <!--	!--	MENU CONTROLS-->

    <!-- header -->
    <!--		navigation bar -->

    <ToolBarMain/>

    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="search-receipt">
        <div class="container p-lg-5 p-md-0">
            <div class="row p-5">
                <div class="receipt-search-container m-5 d-flex flex-column-md animated" id="get-receipt-container">
                    <div class="col-lg-6 receipt-img-container">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">print a receipt for a transaction</h2>
                            <p class="font-14 mb-3 text-capitalize">Make sure all inputs are correct. The process has been made this way to help protect personal data</p>
                        </div>
                    </div>
                    <div class="col-lg-6 p-5 position-relative"> <span class="close-receipt-form position-absolute"> <i data-feather="x"></i></span>
                        <form class="transaction-info">
                            <div class="">
                                <h4 class="mb-5 pb-2 text-capitalize">correctly fill in the form bellow to continue</h4>
                            </div>
                            <div class="row m-0">
                                <div  class="form-group col-sm-12 pl-md-0">
                                    <label for="sel1"><small>What was the transacton type?</small></label>
                                    <select class="form-control mb-3" id="sel1" required>
                                        <option>Parking</option>
                                        <option>Business</option>
                                        <option class="text-capitalize">County bills</option>
                                        <option class="text-capitalize">land rates</option>
                                        <option class="text-capitalize">rents</option>
                                        <option class="text-capitalize">e-Construction</option>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div class="row radio-container m-0">
                                <div class="col-12 pl-md-0 mb-3">
                                    <div class="form-check-inline">
                                        <label class="form-check-label">M-Pesa
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">NBK
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="exampleInputEmail1" class="text-capitalize"><small>what was the refference number for the transaction?</small></label>
                                    <input type="text" class="form-control" id="fname" placeholder="eg M-Pesa ref number" required>
                                </div>
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="identifier" class="text-capitalize"><small>Unique identifire for transaction eg Number plate, bill number, Business ID etc</small></label>
                                    <input type="text" class="form-control" id="identifier" placeholder="identifier" required>
                                </div>
                                <button type="submit" class="btn btn-primary text-capitalize text-white font-14 submit-btn">get receipt</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--form section-->
    <!--	pay confirmation pop up-->
    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="pay-confirmation-pop">
        <div class="container p-md-5 p-sm-0">
            <div class="row p-5 d-flex justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-5 payment-pop-container m-5 m-sm-3 d-flex flex-column-md animated p-0">

                    <div class="col-12 position-relative p-0">
                        <span class="close-receipt-form position-absolute z-index-1 d-none transactions-actions animated text-white" id="close-pricepop"> <i data-feather="x"></i></span>

                        <div class="">
                            <div class="show-amount-loader">
                                <center class="p-5 text-white">
                                    <div class="lds-roller animated"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <div class="confirmed-pay d-none animated"><img src="img/icons/transactions-icons/check.svg"></div>
                                    <p class="text-center text-white m-0 p-0 mb-3  payment-status">pending daily parking payment</p>
                                    <h2 class="mb-5 pb-5 text-capitalize text-white"><sup class="font-14 text-uppercase">Kes</sup>2,000</h2>
                                </center>
                            </div>
                            <div class="col-12 p-lg-5 p-sm-3 ">
                                <div class="col-12 p-0 transacton-instructions">
                                    <h5><strong class="text-capitalize pb-3">payment procedure</strong></h5>
                                    <p class="font-12">follow the following payment procedure in order to complete the payment</p>

                                    <hr>
                                    <ul type="1" class="font-14 pb-5">
                                        <li>1.Check on a <strong>payment pop </strong>up on your phone.</li>
                                        <li>2.Input your <strong>M-pesa pin</strong> and click OK.</li>
                                        <li>3.An <strong>MPESA</strong> confimation SMS will be sent to you.</li>
                                        <li>4.Wait for upto <strong>45 secs</strong> as we process your transaction</li>
                                    </ul>
                                </div>


                                <div class="col-12 p-0 pb-3 d-none transactions-actions animated mt-5">
                                    <h5><strong class="text-capitalize pb-3">Thank You!</strong></h5>
                                    <p class="font-14 text-capitalize m-0">Payment was succesfully received.</p>
                                    <p class="font-14">you can close the pop up or perform the actions bellow</p>
                                    <a href="#" class="btn btn-primary px-5 py-3 text-white text-uppercase font-12 font-sm-10 px-sm-4">Print Receipts</a>
                                    <a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase font-12 font-sm-10 px-sm-4">add to assets</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!--	pay confirmation pop up-->
    <section id="service-form-section" class="">
        <div class="container">
            <div class="row p-5 ">
                <div class="service-form-container  d-flex flex-column-md animated col-12 p-0">
                    <div class="col-lg-7 service-form-img-container" id="biz-img">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">Business Permit</h2>
                            <p class="font-14 mb-3 text-capitalize">fill in and remember to double check details to avoid any errors.</p>
                        </div>
                    </div>
                    <div class="col-lg-5 p-5 position-relative transactions-form-container d-flex">

                        <div v-if="searchPermit" class="col-12 p-0 the-transaction-form animated">
                            <div class="">
                                <p class="mb-5 pb-2 text-capitalize"><strong>correctly fill in the form bellow to continue</strong></p>
                            </div>

                            <form class="transaction-form">
                                <div class="form-group col-sm-12 col-md-12 p-0 mt-2 mb-5">
                                    <label for="mpesa-phone" class="text-capitalize">enter business number</label>
                                    <input v-model="businessID" type="text" class="form-control" id="plot" placeholder="Permit No.">
                                </div>

                                <div class="col-12 p-4 center danger-light-bg lighter-txt price-container position-relative">
                                    <span class="close-price-container position-absolute"> <i data-feather="x"></i></span>
                                    <p class="text-uppercase m-0 font-14"><strong>sub total</strong></p>
                                    <p class="text-uppercase m-0 font-12 d-none">kes 2,000</p>
                                </div>

                                <div class="form-group col-sm-12 col-md-12 p-0 my-3 d-none">
                                    <p class="text-capitalize">payment options</p>
                                    <div class="col-12 p-0 pay-container">
                                        <label for="mpesa" class=" gray-bg w-100 row d-flex justify-content-center align-items-center align-content-center m-0 pay-method">
                                            <div class="col-lg-1 col-md-2 pr-0 radio-side">
                                                <div class="radio-container col-12 p-0 w-100 h-100">
                                                    <input type="radio" class="form-check-input" name="method" required="" id="mpesa" checked>
                                                    <span class="checkmark"></span> </div>
                                            </div>
                                            <div class="col-md-10 col-lg-11 py-4">
                                                <p class="m-0 font-12 text-capitalize"><strong> Mpesa</strong></p>
                                                <p class="font-10px m-0">Pay instantly via mPesa. Enter your <strong>mpesa phone number</strong> and a payment request will be sent automatically to your phone</p>
                                            </div>
                                            <span class="checkmark"></span>
                                        </label>
                                        <label for="airtel" class=" w-100 row d-flex justify-content-center align-items-center align-content-center m-0 pay-method">
                                            <div class="col-lg-1 col-md-2 pr-0 radio-side">
                                                <div class="radio-container col-12 p-0 w-100 h-100">
                                                    <input type="radio" class="form-check-input" name="method" required="" id="airtel">
                                                    <span class="checkmark"></span> </div>
                                            </div>
                                            <div class="col-md-10 col-lg-11 py-4">
                                                <p class="m-0 font-12 text-capitalize"><strong>airtel money</strong></p>
                                                <p class="font-10px m-0">provide your <strong>aitel money phone number</strong> and a payment request will be sent to your phone</p>
                                            </div>
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-12 col-md-12 p-0 my-2">
                                        <label for="mpesa-phone" class="text-capitalize">mpesa phone number</label>
                                        <input type="text" class="form-control" id="mpesa-phone" placeholder="eg 07123...">
                                    </div>
                                </div>

                                <div class="col-12 px-0 text-uppercase mt-5 pt-5">
                                    <button @click="printTradePermit" type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0 transaction-form-btn"><span class="btn-text text-uppercase font-12">continue</span> <i data-feather="arrow-right" class="ml-2 float-right pull-right"></i></button>
                                </div>
                                <div v-if="message!=null" class="alert alert-danger" role="alert">
                                    {{message}}
                                </div>
                            </form>
                        </div>
                        <div v-if="permitDetails" class="col-12 p-0 animated details-confirm">
                            <div class="">
                                <p class="mb-5 pb-2 text-capitalize">
                                    <span class="back-toform" title="back to transactions form"><i data-feather="arrow-left"  class="mr-3 float-left"></i></span><strong>confirm your transaction details</strong>
                                </p>
                                <hr class="mt-1 p-0">
                                <p><strong class="text-capitalize">buiness details</strong></p>

                                <div class="transactions-details-container text-capitalize">
                                    <p class="m-0"><span>business name</span></p>
                                    <strong>{{permit.businessName}}</strong>
                                    <hr>
                                    <p class="m-0"><span>business ID</span></p>
                                    <strong>{{permit.businessID}}</strong>
                                    <hr>

                                    <p class="m-0"> <span>Business activities</span></p>
                                    <strong class="text-uppercase">{{permit.businessSubCategory}}</strong>
                                    <hr>

                                    <p class="m-0"> <span>Expiry</span></p>
                                    <strong class="text-uppercase">{{permit.endDate}}</strong>
                                    <hr>
                                    <div class=" text-right amount-container d-none">
                                        <p><span class="text-uppercase text-left">print permit</span> <strong class="d-none"> KES 2,000</strong></p>
                                    </div>
                                    <hr>


                                        <div class="form-group col-sm-12 col-md-12 p-0 d-none">
                                            <label for="number-plate" class="text-capitalize">amount to pay</label>
                                            <input type="text" class="form-control" id="amount" placeholder="Enter amount to pay">
                                        </div>

                                        <div class="form-group col-sm-12 col-md-12 p-0 d-none">
                                            <label for="mpesa-number" class="text-capitalize">M-pesa phone number</label>
                                            <input type="text" class="form-control" id="mpesa-number" placeholder="eg 0712...">
                                        </div>

                                        <div class="col-12 p-0 text-uppercase mt-5">
                                            <a @click="printPermit" type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0">
                                                <div class="btn-txt animated">
                                                    <span class="btn-text text-uppercase font-12">print permit</span>
                                                    <i data-feather="arrow-right" class="ml-2 float-right pull-right">
                                                    </i>
                                                </div>
                                                <div class="lds-ellipsis d-none animated"><div></div><div></div><div></div><div></div></div>
                                            </a>
                                        </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--form section-->

    <!-- download -->
    <!--			footer-->
    <FooterBar/>
    <!--			footer-->

    <!--scripts-->
    </body>

</template>

<script>
    import {execute, trade} from "@/api";
    import FooterBar from "@/components/Footer.vue";
    import ToolBarMain from "@/components/ToolBar.vue";

    export default {
        name: "PrintPermit",
      components: {ToolBarMain, FooterBar},
        data() {
            return{
                businessID:'',
                message: null,
                searchPermit: true,
                permitDetails: false,
                permit: {
                    id: null,
                    billNo: null,
                    businessID: null,
                    businessName: null,
                    duration: null,
                    billTotal: null,
                    receiptAmount: null,
                    billBalance: null,
                    startDate: null,
                    endDate: null,
                    statusID: null,
                    status: null,
                    printable: null,
                    issuingOfficer: null,
                    designation: null,
                    dateOfIssue: null,
                    stageStatus: null,
                    comments: null,
                    incomeTypeId: null,
                    feeID: null,
                    subCountyName: null,
                    wardName: null,
                    dateCreated: null,
                    subCountyID: null,
                    wardID: null,
                    plotNumber: null,
                    physicalAddress: null,
                    buildingName: null,
                    buildingOccupancy: null,
                    floorNo: null,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    businessEmail: null,
                    postalAddress: null,
                    postalCode: null,
                    businessPhone: null,
                    contactPersonNames: null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames: null,
                    ownerID: null,
                    ownerPhone: null,
                    ownerEmail: null,
                    kraPin: null,
                    createdBy: null,
                    createdByIDNo: null,
                    lat: null,
                    lng: null,
                    lastRenewed: null,
                    permitsCount: null,
                    conservancy: null,
                    fireLicence: null,
                    liquorLicence: null,
                    businessType: null
                }

            }
        },
        methods:{

            printPermit() {
                //  this.$refs.closeReceived.click();
                localStorage['params'] = JSON.stringify({
                    businessID: this.permit.businessID
                })
                const routeData = this.$router.resolve({name: 'permit'});
                window.open(routeData.href, '_blank');
            },
          printTradePermit(){

                if(this.businessID === ''){
                    this.message = "Enter business ID"
                    return
                }

                const data = new FormData();
                data.append("function", "printTradePermit");
                data.append("businessID", this.businessID);
                execute(data,trade)
                    .then((res) =>{
                        if (res.data.success) {

                            this.searchPermit= false
                            this.permitDetails = true
                            this.permit = res.data.data.permit
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
         }
    }
</script>

<style scoped>

</style>