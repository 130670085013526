<template>
  <!-- download -->
  <section id="download" class="clearfix parallax-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <h2>{{capitalizeText(county.countyName)}} e-Payments Platforms</h2>
        </div>
      </div>
      <!-- row -->

      <!-- row -->
      <div class="row">
        <!-- download-app -->
        <div class="col-sm-4"> <a href="{{county.mobileApp}}" class="download-app mb-2"> <img src="img/logo/google-play.png" alt="Image" class="img-responsive"> <span class="pull-left"> <span>available on</span> <strong class="text-white">Google Play</strong> </span> </a> </div>
        <!-- download-app -->

        <!-- download-app -->
        <div class="col-sm-4"> <a href="#" class="download-app mb-2"> <img src="img/logo/ussd.svg" alt="Image" class="img-responsive"> <span class="pull-left"> <span>USSD services</span> <strong class="text-white">{{county.ussdCode}}</strong> </span> </a> </div>
        <!-- download-app -->

        <!-- download-app -->
        <div class="col-sm-4"> <a href="#" class="download-app mb-2"> <img src="img/logo/web.svg" alt="Image" class="img-responsive"> <span class="pull-left"> <span>available on</span> <strong class="text-white">Web Portal</strong> </span> </a> </div>
        <!-- download-app -->
      </div>
      <!-- row -->
    </div>
    <!-- contaioner -->
  </section>
  <!-- download -->

  <!--	pop up section-->
  <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center d-none animated" id="pop">
    <div class="container">
      <div class="row">
        <div class="coll-12">
          <div class="pop-item p-5 plposition-relative animated m-5"> <a href="#" class="close-pop position-absolute text-white"><i data-feather="x"></i></a>
            <div class="col-sm-10 col-md-8 col-lg-6 p-md-5"> <span class="text-uppercase p-3 bg-light-green mb-3 font-12">made simple</span>
              <h2 class="mb-4 mt-4 text-capitalize text-white">make your transactions seamless</h2>
              <p class="font-14 mb-3 text-capitalize">make your experience while transacting simple and easy by registering for an account. by doing so you will get to store your assests and track your transactions history.</p>
              <form class="mb-5 form-inline">
                <input type="email" class="form-control font-14 mr-3 mb-3"   title="enter email address" placeholder="Enter email to continue">
                <button type="submit" class="btn btn-secondary px-4 font-14 text-black mb-3">Continue</button>
              </form>
              <a class="d-flex align-items-center text-white pt-4" href="#"> <span class="pop-pointer mr-2"><i data-feather="chevron-right"></i></span> <span class="text-capitalize">Or Login to your account</span> </a> </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--	pop up section-->

  <footer id="footer" class="clearfix">
    <!-- footer-top -->
    <section class="footer-top clearfix bg-dark text-capitalize">
      <div class="container">
        <div class="row">
          <!-- footer-widget -->
          <div class="col-sm-3">
            <div class="footer-widget news-letter mb-5">
              <div class="">
                <h3 class="text-white text-capitalize">{{capitalizeText(county.countyName)}} County Government Self Service Portal</h3>
                <p class="font-12">Make payments easily and quickly. Create account and get to enjoy paying for {{capitalizeText(county.countyName)}} City County services online.</p>
              </div>
              <img :src="county.logo" height="60px;" class="pr-2"> </div>
          </div>
          <!-- footer-widget -->

          <!-- footer-widget -->
          <div class="col-sm-3">
            <div class="footer-widget mb-5">
              <h3 class="text-white">My account</h3>
              <ul>
                <li><a href="#">my profile</a></li>
                <li><a href="#">transactions history</a></li>
                <li><a href="#">my assets</a></li>
                <li><a href="#">my bills</a></li>
                <li><a href="#">Change Password</a></li>
                <li><a href="#">log out</a></li>
              </ul>
            </div>
          </div>
          <!-- footer-widget -->

          <!-- footer-widget -->
          <div class="col-sm-3">
            <div class="footer-widget social-widget mb-5">
              <h3 class="text-white">quick links</h3>
              <ul>
                <li><a  href="javascript: void(0);">daily parking</a></li>
                <li><a  href="javascript: void(0);">parking penalties</a></li>
                <li><a  href="javascript: void(0);">pay for bills</a></li>
                <li><a  href="javascript: void(0);">seasonal parking</a></li>
                <li><a  href="javascript: void(0);">e-construction</a></li>
                <li><a  href="javascript: void(0);">FAQ</a></li>
              </ul>
            </div>
          </div>
          <!-- footer-widget -->

          <!-- footer-widget -->
          <div class="col-sm-3">
            <div class="footer-widget news-letter mb-5">
              <h3 class="text-white text-capitalize">Android app</h3>
              <p class="font-12">Download The Mobile App From Google Plays-tore And Get To Enjoy County Services At The Tip Of Your Fingers</p>
              <a href="https://play.google.com/store/apps/details?id=com.aw.epayments" class="p-3"> <img src="img/logo/google-play-66442243.svg" alt="Google Play Store Badge" class="FooterNew-appStoreIcon-image"> </a>
              <!-- form -->
              <form action="" class="d-none">
                <input type="email" class="form-control" placeholder="Your E-mail">
                <button type="submit" class="btn btn-primary">Subscribe</button>
              </form>
              <!-- form -->
            </div>
          </div>
          <!-- footer-widget -->
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>
    <!-- footer-top -->

    <div class="footer-bottom clearfix  bg-dark">
      <div class="container">
        <p class="d-none">Copyright © 2021  </p>
        <div class="row">
          <div class="col-6 p-md-0 px-sm-3">
            <p class="text-capitalize m-0 text-white font-12">Help Line</p>
            <h4 class="SiteStat-value text-white">{{county.contact}}</h4>
          </div>
          <div class="col-6 px-sm-3 p-md-0 d-none"> <img :src="county.powered" class="float-right" height="35px"> </div>
        </div>
      </div>
    </div>
    <!-- footer-bottom -->
  </footer>
</template>

<script>

import {authUrl, execute} from "@/api";

export default {
  name: "FooterBar",
  data() {
    return{
      county: {
        id: null,
        countyID: null,
        countyName: null,
        tagline:null,
        smsusername: null,
        smspassword:null,
        contact: null,
        center: null,
        address:null,
        email: null,
        headline: null,
        tagline2:null,
        ussd: null,
        logo:null,
        bank: null,
        powered: null,
        stateLogo: null,
        seal: null,
        signature: null,
        QRLink:null,
        ussdCode:null,
        mobileApp:null
      }
    }},
  mounted: function () {
    this.getCounty()
  },
  methods:{
    gotTo(route){
      this.$router.push(route);
    },
    getCounty(){
      const data = new FormData();
      data.append("function", "getCounty");
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.county = res.data.data.county
            }else{
              // alert(res.data.message)
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    capitalizeText(text) {

      if(text == null){
        // Convert the text to lowercase and then capitalize the first letter
        return "";
      }else {
        // Convert the text to lowercase and then capitalize the first letter
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }


    }
  }

}
</script>

<style scoped>

</style>