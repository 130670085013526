<template>

  <html>
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="author" content="Theme Region">
    <meta name="description" content="">
    <title>Daily parking</title>
    <!-- Bootstrap CSS -->
    <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">
    <link href="css/animate/animate.min.css" rel="stylesheet">
    <link rel="stylesheet" href="css/bootstrap.css">
    <!--	bootstrap css-->
    <!--  CSS -->
    <link rel="stylesheet" href="css/navigation-temp.css">
    <link rel="stylesheet" href="css/style.css">
    <!-- <link rel="stylesheet" href="css/style2.css"> -->

    <link rel="stylesheet" href="css/form.css">
  </head>

  <body>
  <ToolBarMain/>


  <!--	pay confirmation pop up-->
  <section v-if="payConfirmationPop" class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated" id="pay-confirmation-pop">
    <div class="container p-md-5 p-sm-0">
      <div class="row p-5 d-flex justify-content-center">
        <div class="col-sm-12 col-md-6 col-lg-5 payment-pop-container m-5 m-sm-3 d-flex flex-column-md animated p-0">

          <div class="col-12 position-relative p-0">
            <span class="close-receipt-form position-absolute z-index-1 d-none transactions-actions animated text-white" id="close-pricepop"> <i data-feather="x"></i></span>

            <div class="">
              <div class="show-amount-loader">
                <center class="p-5 text-white">
                  <div v-if="loadingPayment" class="lds-roller animated"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  <div v-if="paymentReceived" class="confirmed-pay animated"><img src="img/icons/transactions-icons/check.svg"></div>
                  <p v-if="!paymentReceived" class="text-center text-white m-0 p-0 mb-3  payment-status">pending {{billDetails.incomeTypeDescription}} payment</p>
                  <h2 class="mb-5 pb-5 text-capitalize text-white"><sup class="font-14 text-uppercase">Kes</sup>{{amountToPay}}</h2>
                </center>
              </div>
              <div class="col-12 p-lg-5 p-sm-3 ">
                <div v-if="!paymentReceived" class="col-12 p-0 transacton-instructions">
                  <h5><strong class="text-capitalize pb-3">payment procedure</strong></h5>
                  <p class="font-12">follow the following payment procedure in order to complete the payment</p>

                  <hr>
                  <ul type="1" class="font-14 pb-5">
                    <li>1.Check on a <strong>payment pop </strong>up on your phone.</li>
                    <li>2.Input your <strong>M-pesa pin</strong> and click OK.</li>
                    <li>3.An <strong>MPESA</strong> confimation SMS will be sent to you.</li>
                    <li>4.Wait for upto <strong>45 secs</strong> as we process your transaction</li>
                  </ul>
                </div>

                <div v-if="paymentReceived" class="col-12 p-0 pb-3 transactions-actions animated mt-5">
                  <h5><strong class="text-capitalize pb-3">Thank You {{transaction.names}}!</strong></h5>
                  <p class="font-14 text-capitalize m-0">Payment was successfully received. <strong>{{transaction.transaction_code}}</strong></p>
                  <p class="font-14">you can close the pop up or perform the actions bellow</p>
                  <a @click="printReceipt()" href="javascript: void(0);" class="btn btn-primary px-5 py-3 text-white text-uppercase font-12 font-sm-10 px-sm-4">Print Receipts</a>
                  <!--<a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase font-12 font-sm-10 px-sm-4">add to assets</a>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--	pay confirmation pop up-->



  <section id="service-form-section" class="">
    <div class="container">
      <div class="row p-5 ">
        <div class="service-form-container  d-flex flex-column-md animated col-12 p-0">
          <div class="col-lg-7 service-form-img-container" id="bill-img">
            <div class="col-lg-8 col-md-12 position-relative p-5">
              <h2 class="mb-4 text-capitalize text-white">Bills</h2>
              <p class="font-14 mb-3 text-capitalize">fill in and remember to double check details to avoid any errors.</p>
            </div>
          </div>
          <div class="col-lg-5 p-5 position-relative transactions-form-container d-flex">

            <div v-if="!billResult" class="col-12 p-0 the-transaction-form animated">
              <div class="">
                <p class="mb-5 pb-2 text-capitalize"><strong>correctly fill in the form bellow to continue</strong></p>
              </div>
              <form class="transaction-form">
                <div class="form-group col-sm-12 col-md-12 p-0 mt-2 mb-5">
                  <label for="mpesa-phone" class="text-capitalize">enter bill number</label>
                  <input v-model="billNo" type="text" class="form-control" id="plot" placeholder="">
                </div>

                <div class="col-12 p-4 center danger-light-bg lighter-txt price-container position-relative">
                  <span class="close-price-container position-absolute"> <i data-feather="x"></i></span>
                  <p class="text-uppercase m-0 font-14"><strong>sub total</strong></p>
                  <p class="text-uppercase m-0 font-12">kes 2,000</p>
                </div>
                <div class="form-group col-sm-12 col-md-12 p-0 my-3 d-none">
                  <p class="text-capitalize">payment options</p>
                  <div class="col-12 p-0 pay-container">
                    <label for="mpesa" class=" gray-bg w-100 row d-flex justify-content-center align-items-center align-content-center m-0 pay-method">
                      <div class="col-lg-1 col-md-2 pr-0 radio-side">
                        <div class="radio-container col-12 p-0 w-100 h-100">
                          <input type="radio" class="form-check-input" name="method" required="" id="mpesa" checked>
                          <span class="checkmark"></span> </div>
                      </div>
                      <div class="col-md-10 col-lg-11 py-4">
                        <p class="m-0 font-12 text-capitalize"><strong> Mpesa</strong></p>
                        <p class="font-10px m-0">Pay instantly via mPesa. Enter your <strong>mpesa phone number</strong> and a payment request will be sent automatically to your phone</p>
                      </div>
                      <span class="checkmark"></span>
                    </label>
                    <label for="airtel" class=" w-100 row d-flex justify-content-center align-items-center align-content-center m-0 pay-method">
                      <div class="col-lg-1 col-md-2 pr-0 radio-side">
                        <div class="radio-container col-12 p-0 w-100 h-100">
                          <input type="radio" class="form-check-input" name="method" required="" id="airtel">
                          <span class="checkmark"></span> </div>
                      </div>
                      <div class="col-md-10 col-lg-11 py-4">
                        <p class="m-0 font-12 text-capitalize"><strong>airtel money</strong></p>
                        <p class="font-10px m-0">provide your <strong>aitel money phone number</strong> and a payment request will be sent to your phone</p>
                      </div>
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="form-group col-sm-12 col-md-12 p-0 my-2">
                    <label for="mpesa-phone" class="text-capitalize">mpesa phone number</label>
                    <input type="text" class="form-control" id="mpesa-phone" placeholder="eg 07123...">
                  </div>
                </div>

                <div class="col-12 px-0 text-uppercase mt-5 pt-5">
                  <button @click="getBill()" type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0"><span class="btn-text text-uppercase font-12">check bill details</span> <i data-feather="arrow-right" class="ml-2 float-right pull-right"></i></button>
                </div>
              </form>

              <div style="margin-top: 20px" v-if="message !==null" class="warning-message danger">
                {{message}}
              </div>

            </div>

            <div v-if="billResult" class="col-12 p-0 animated details-confirm">
              <div class="">
                <p class="mb-5 pb-2 text-capitalize">
                  <span class="back-toform" title="back to transactions form"><i data-feather="arrow-left"  class="mr-3 float-left"></i></span><strong>confirm your transaction details</strong>
                </p>
                <hr class="mt-1 p-0">
                <p><strong class="text-capitalize">house details</strong></p>

                <div class="transactions-details-container text-capitalize">
                  <p class="m-0"><span>Description</span></p>
                  <strong>{{billDetails.incomeTypeDescription}}</strong>
                  <hr>
                  <p class="m-0"><span>Item</span></p>
                  <strong>{{billDetails.feeDescription}}</strong>
                  <hr>

                  <p class="m-0"> <span>Billed Amount</span></p>
                  <strong class="text-uppercase">KES {{billDetails.detailAmount}}</strong>
                  <hr>

                  <p class="m-0"> <span>Date</span></p>
                  <strong class="text-uppercase">{{billDetails.dateCreated}}</strong>
                  <hr>
                  <div class=" text-right amount-container">
                    <p><span class="text-uppercase text-left">current balance</span> <strong> KES {{billDetails.billBalance}}</strong></p>
                  </div>
                  <hr>

                  <form>

                    <div class="form-group col-sm-12 col-md-12 p-0">
                      <label for="number-plate" class="text-capitalize">amount to pay</label>
                      <input v-model="amountToPay" type="text" class="form-control" id="amount" placeholder="Enter amount to pay">
                    </div>

                    <div class="form-group col-sm-12 col-md-12 p-0">
                      <label for="mpesa-number" class="text-capitalize">M-pesa phone number</label>
                      <input v-model="phoneNumber" type="text" class="form-control" id="mpesa-number" placeholder="eg 0712...">
                    </div>

                    <div class="col-12 p-0 text-uppercase mt-5">
                      <button type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0 confirm-btn">
                        <div class="btn-txt animated">
                          <span @click="customerPayBillOnline" class="btn-text text-uppercase font-12">pay kes {{amountToPay}}</span>
                          <i data-feather="arrow-right" class="ml-2 float-right pull-right">
                          </i>
                        </div>
                        <div v-if="loading" class="lds-ellipsis animated"><div></div><div></div><div></div><div></div></div>
                      </button>
                    </div>

                  </form>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </section>



  <FooterBar/>


  </body>

  </html>

</template>


<script>

/* eslint-disable */
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import {paysol, biller, execute, execute2, executeDownload, getRight, parking, authUrl} from "@/api";
import ToolBarMain from "@/components/ToolBar.vue";
import FooterBar from "@/components/Footer.vue";

export default {
  name: "DailyParking",
  components: {FooterBar, ToolBarMain},
  data() {
    return{
      amountToPay: null,
      billResult: false,
      message: null,
      county: {
        id: null,
        countyID: null,
        countyName: null,
        tagline:null,
        smsusername: null,
        smspassword:null,
        contact: null,
        center: null,
        address:null,
        email: null,
        headline: null,
        tagline2:null,
        ussd: null,
        logo:null,
        bank: null,
        powered: null,
        stateLogo: null,
        seal: null,
        signature: null,
        QRLink:null,
        ussdCode:null
      },
      payConfirmationPop: false,
      loading: false,
      loadingPayment: false,
      paymentReceived: false,
      billGenerated: {
        billNo: null,
        payBillNo: null,
        amount: null,
        success: null
      },

      billNo:'',
      numberPlate: null,
      phoneNumber: null,
      incomeTypes: [{
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix:null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategory: null,
        zoneCategoryID: null,
        onUssd: null,
        dailyTargets: null,
        monthlyTargets: null,
        financialYearTargets: null,
        brimsCode: null
      }],
      incomeType: {
        incomeTypeId: null,
        incomeTypeDescription: null,
        incomeTypePrefix:null,
        fundDescription: null,
        status: null,
        departmentID: null,
        zoneCategory: null,
        zoneCategoryID: null,
        onUssd: null,
        dailyTargets: null,
        monthlyTargets: null,
        financialYearTargets: null,
        brimsCode: null
      },
      feesAndCharges: [{
        feeId: null,
        feeDescription:null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc: null,
        costCenterNo: null,
        zone: null,
        typeDescription: null,
        prorated: null,
        duration: null,
        brimsCode: null
      }],
      feesAndCharge: {
        feeId: null,
        feeDescription:null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc: null,
        costCenterNo: null,
        zone: null,
        typeDescription: null,
        prorated: null,
        duration: null,
        brimsCode: null
      },
      transaction: {
        id: null,
        account_to: null,
        account_from: null,
        amount: null,
        ref: null,
        transaction_code: null,
        payment_mode: null,
        company: null,
        status: null,
        merchant_request_id: null,
        date: null,
        balance: null,
        names: null,
        email: null,
        posted: null,
        postedToEric: null,
        TransactionDesc: null,
        zone: null,
        street: null,
        user_id: null,
        username: null,
        user_full_name: null,
        msisdn: null,
        postedToBiller: null,
        queried: null,
        postedToHealth: null,
        currency: null,
        message: null,
        response: null,
        remarks: null
      },
      receiptDetails: {
        id: '',
        receiptNo: null,
        billNo: '',
        detailAmount: '',
        receiptAmount: '',
        billBalance: '',
        costCenterNo: '',
        accountNo: '',
        incomeTypeDescription: '',
        feeID: '',
        wardID: '',
        subCountyID:'',
        currency: '',
        source: '',
        transactionCode: '',
        paidBy: '',
        dateCreated: '',
        dateModified: '',
        createdBy: '',
        modifiedBy: '',
        isActive: '',
        status: ''
      },
      billDetails:{
        id:'',
        billNo:'',
        incomeTypeID:'',
        incomeTypeDescription:'',
        costCenterNo:'',
        accountNo:'',
        feeID:'',
        detailAmount:'',
        receiptAmount:'',
        billBalance:'',
        wardID:'',
        subCountyID:'',
        status:'',
        dateCreated:''
      },
      billInfo:[],
      paymentData: {
        bankReceiptNo: null,
        accNo: null,
        payBillNo: null,
        amount: null,
        phoneNumber: null,
        success: false,
        paymentMode:null,
        sessionNames:null,
        sessionIdNo:null,
        sessionPhoneNumber:null
      },
      alert:{
        success: false,
        info: false,
        failed:false
      },
      bank: {
        id:null,
        bankID: null,
        bank: null,
        payBill: null,
      }

    }
  },
  mounted: function () {
    this.getCounty()
    this.mainJS()
  },
  watch:{
    incomeType: function () {
      this.getFeesAndCharges()
    } ,
  },
  methods:{
    gotTo(route){
      this.$router.push(route);
    },
    mainJS() {
      $(window).on('load', function() {
        $('#myModal').modal('show');
        function explode(){
          $('#pop').removeClass('d-none');
          $('#pop').addClass('fadeIn');
          $('.hand-phone').addClass('bounceInUp')

        }

//	hiding biz creator

        $('#create-next').on('click', function(){
          bizinfo_hide();
        });
        $('#location-next').on('click', function(){
          location_hide();
        });
        $('#owners-next').on('click', function(){
          owner_hide();
        });

//	span clicking


        $('.start-clickable').on('click', function(){
          bizinfo_hide2();

        });

        // Get the element, add a click listener...
        document.getElementById("point-indicator").addEventListener("click", function(e) {
          // e.target is the CHANGING Element!
          // If it was an input item
          if(e.target && e.target.matches(".location-clickable")) {
            // List item found!  Output the ID!
            location_hide();
          }
        });

        // Get the element, add a click listener...
        document.getElementById("point-indicator").addEventListener("click", function(e) {
          // e.target is the CHANGING Element!
          // If it was an input item
          if(e.target && e.target.matches(".info-clickable")) {
            // List item found!  Output the ID!
            bizinfo_hide();
          }
        });


        // Get the element, add a click listener...
        document.getElementById("activity-btns").addEventListener("click", function(e) {
          // e.target is the CHANGING Element!
          // If it was an input item
          if(e.target && e.target.matches(".text-prev")) {
            // List item found!  Output the ID!

            location_hide();
          }
        });

        // Get the element, add a click listener...
        document.getElementById("location-btns").addEventListener("click", function(e) {
          // e.target is the CHANGING Element!
          // If it was an input item
          if(e.target && e.target.matches(".text-prev")) {
            // List item found!  Output the ID!

            bizinfo_hide2();
          }
        });

        // Get the element, add a click listener...
        document.getElementById("owner-btns").addEventListener("click", function(e) {
          // e.target is the CHANGING Element!
          // If it was an input item
          if(e.target && e.target.matches(".text-prev")) {
            // List item found!  Output the ID!

            bizinfo_hide();
          }
        });

        $('location-info .text-prev').on('click',function(){
          bizinfo_hide2();
        });


//	$('#activity-next').on('click', function(){
//		activity_hide();
//	});
        function bizinfo_hide2(){
//			$('.biz-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
          $('.biz-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
          $('.point-indicator span').eq(0).addClass('active').addClass('start-clickable').siblings().removeClass('active');
        }

        function bizinfo_hide(){
//			$('.biz-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
          $('.location-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
          $('.point-indicator span').eq(1).addClass('active').addClass('info-clickable').siblings().removeClass('active');
        }

        function location_hide(){
          $('.owners-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
          $('.point-indicator span').eq(2).addClass('active').addClass('location-clickable').siblings().removeClass('active');
        }
        function owner_hide(){
          $('.activity-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft').siblings().addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
          $('.point-indicator span').eq(3).addClass('active').addClass('owner-clickable').siblings().removeClass('active');
        }
//	function owner_hide(){
//			$('.activity-info').addClass('d-none').addClass('bounceOutRight').removeClass('bounceInLeft');
//			$('.activity-info').removeClass('d-none').removeClass('bounceOutRight').addClass('bounceInLeft');
//		}




        function popitem(){
          $('.pop-item').addClass('tada');
        }
        setTimeout(explode, 5000);
        setTimeout(popitem, 5200);

        $('.close-pop').on('click',function(){
          closepop();
        });

        $('#close-pricepop').on('click',function(){
          closepaypop();
        });



        function closepop(){
          setTimeout($('#pop').addClass('d-none'),2000);
          $('#pop').removeClass('fadeIn');
          $('.pop-item').removeClass('tada');

          $('#pop').addClass('zoomOut');
          $('.pop-item').addClass('rollOut');

        }

        function closereceiptform(){
          setTimeout($('#search-receipt').addClass('d-none'),2000);
          $('#search-receipt').removeClass('fadeIn');
          $('#get-receipt-container').removeClass('tada');

          $('#search-receipt').addClass('zoomOut');
          $('#get-receipt-container').addClass('rollOut');

        }


        function closepaypop(){
          $('#pay-confirmation-pop').addClass('d-none');
          $('#pay-confirmation-pop').removeClass(' zoomOut');
        }


        function showreceiptform(){
          $('#search-receipt').removeClass('zoomOut');
          $('#search-receipt').removeClass('d-none');
          $('#search-receipt').addClass('fadeIn');
          $('#get-receipt-container').removeClass('rollOut');
          $('#get-receipt-container').addClass('tada');
        }

        $('.close-receipt-form').on('click',function(){
          closereceiptform();
        });

        $('#get-a-receipt').on('click',function(){
          showreceiptform();
        });

        $('.get-a-receipt').on('click',function(){
          showreceiptform();
        });


      });

      (function($) {

        function closesublinks() {
          $(".construction1").addClass("left");
          $(".wallet1").addClass("left");
          $(".parking1").addClass("left");
          $(".rent1").addClass("left");
          $(".unified-biz-permit1").addClass("left");
          $('.land-rate1').addClass("left");

        }

        $(".option").on("click", function() {
          checkWidth();
        });
        $(".hidden-links-container").on("click", function() {
          $(this).hide();
        });

        function checkWidth() {
          var windowSize = $(window).width();

          if (windowSize < 993) {
            $(".hidden-links-container").css("display", "flex");
          }
        }

        // Execute on load

        $(".option").on("click", function() {
          closesublinks();
          $(this).addClass("active").siblings().removeClass("active");
          $(this).children(".active-triangle").removeClass("display-none");
          $(this).siblings().children(".active-triangle").addClass("display-none");
        });

        $('.business-permit').on("click", function() {
          $('.unified-biz-permit1').removeClass("left");
        });

        $('.construction').on("click", function() {
          closesublinks();
          $('.construction1').removeClass("left");
        });

        $('.E-Wallet').on("click", function() {
          $('.wallet1').removeClass("left");
        });

        $('.rents').on("click", function() {
          $('.rent1').removeClass("left");
        });

        $('.parking').on("click", function() {
          $('.parking1').removeClass("left");
        });

        $('.land-rates').on("click", function() {
          $('.land-rate1').removeClass("left");
        });

        $(".name-discount").on("click", function() {
          $(".toogle-user-container").slideToggle();
        });

        $(".dp").on("click", function() {
          $(".toogle-user-container").slideToggle();
        });

        $(".toogle-user-container").on("click", function() {
          $(".toogle-user-container").slideUp();
        });

        //	payment methods mpesa direct
        $(".m-pesa-direct").on("click", function() {
          $(".m-pesa-direct-inputs").slideToggle().siblings(".payment-details").slideUp();
          $(this).siblings().children("span").css("opacity", "0");
          $(".direct-indicator").css("opacity", "1");


        });

        //	payment methods mpesa standard
        $(".mpesa-standard").on("click", function() {
          $(".m-pesa-standard-inputs").slideToggle().siblings(".payment-details").slideUp();
          $(this).siblings().children("span").css("opacity", "0");
          $(".std-indicator").css("opacity", "1");
        });

        //	payment methods credits
        $(".viza-master").on("click", function() {
          $(".credit-payment-inputs").slideToggle().siblings(".payment-details").slideUp();
          $(this).siblings().children("span").css("opacity", "0");
          $(".visa-indicator").css("opacity", "1");
        });

        //	payment methods airtel
        $(".airtell").on("click", function() {
          $(".airtell-inputs").slideToggle().siblings(".payment-details").slideUp();
          $(this).siblings().children("span").css("opacity", "0");
          $(".airtel-indicator").css("opacity", "1");
        });


        $('.user-nav .wrap').on('click', function(){
          $('.user-nav .wrap').removeClass('display-tri');
          $('.user-nav .wrap').removeClass('active-item')
          $(this).addClass('display-tri');
          $(this).addClass('active-item');
        });

        $('.acount-details-form input').on('change', function(){
          $('.submit-btn').prop("disabled", false);
        })



      })(jQuery);

      $(document).ready(function() {
        // Toggles the side menu and overlay
        $('.toogle-btn').on('click', function() {
          $(".overlay").toggleClass("display-none");
          // $('.toogle-menu-container').toggleClass('hide show');
        })

        // Closes the side menu and removes the overlay when the overlay is clicked
        $('.overlay').on('click', function() {
          // $('.toogle-menu-container').toggleClass('hide show');
          $(".overlay").toggleClass("display-none");
        })

        // ScrollMagic for Options
        var controller = new ScrollMagic.Controller();
        var ourScene = new ScrollMagic.Scene({
          triggerElement: '.option',
          triggerHook: 0.7
        }).setClassToggle('.option', 'fade-in')
            .addTo(controller)
      });



//this part affects the transactions page
      (function($) {
        $(".main-transaction-container").on("click", function(){
          returnpannels();
          var parent=$(this).parent();
          $(this).addClass("active-transaction").parent().siblings().children("active-transaction").removeClass("active-transaction");
          $(this).siblings(".transaction-more-details").addClass("display-flex");
          $(this).siblings(".transaction-more-details").slideDown();
          parente.siblings(".transaction-more-details").removeClass("display-flex");

//		$(".transaction-more-details").eq(index).removeClass("display-none");
//		console.log(index);

        });

        $(".close-panel-container").on("click", function(){
          $(this).parent().slideUp();
          $(this).parent().removeClass("display-flex");
        });




        function returnpannels()
        {
          $(".main-transaction-container").removeClass("active-transaction");
          $(".transaction-more-details").slideUp();
          $(".transaction-more-details").removeClass("display-flex");

        }


//	showing and removing assets form

        $('#add-asset').on('click', function(){
          showassetsform();
        });

        $('#close-asset-form').on('click', function(){
          hideassetsform();
        })

        function showassetsform(){
          $(".assets-form-container").removeClass('left');
          $('.assets-container').addClass('right');

        }

        function hideassetsform(){
          $(".assets-form-container").addClass('left');
          $('.assets-container').removeClass('right');

        }
        $('.drop-title').on('click', function(){
          $(this).parent().siblings('.drop-container').slideToggle();
        })

        $('.assets-container .drop-container li').on('click', function(){
          var thetext=$(this).text();
          $('.assets-container .the-assets-text').text(thetext);
          $('.drop-container').slideUp();
          console.log(thetext);
        });


//	for the assets form

        $('.assets-form-container .drop-container li').on('click', function(){
          var thetext=$(this).text();
          $('.assets-form-container .the-assets-text').text(thetext);
          $('.drop-container').slideUp();
          console.log(thetext);
        });


//	interchanging forms
        $('.the-assets-form-group li').on('click', function(){
          $('.the-assets-form').addClass('d-none');
        });

        $('.nyumba').on('click',function(){
          $('.house-form-inputs').removeClass('d-none');
        });

        $('.gari').on('click',function(){
          $('.car-form-inputs').removeClass('d-none');
        });

        $('.soko').on('click',function(){
          $('.stalls-form-inputs').removeClass('d-none');
        });

        $('.gari').on('click',function(){
          $('.car-form-inputs').removeClass('d-none');
        });

        $('.shamba').on('click',function(){
          $('.land-form-inputs').removeClass('d-none');
        });


//	payment method
        $(".pay-container .pay-method").on('click', function(){
          $(this).addClass('gray-bg').siblings().removeClass('gray-bg');
        });

//	closing price container


//	function showhideprice2(parking_zone){
//		if(parking_zone!="-- select off-street zone --"){
//		$('.price-container').slideDown();
//	}
        function showhideprice(parking_zone, car_type){
          if((parking_zone!="-- select parking zone --") && (car_type!="-- select vehicle type --")){
            $('.price-container').slideDown();
          }
          else if((parking_zone=="-- select parking zone --") || (car_type=="-- select vehicle type --")){
            $('.price-container').slideUp();
          }
        }

        $('.close-price-container').on('click', function(){
          $(this).parent().slideUp();
        });

        $('#zone').on('change',function(){
          var parking_zone=$('#zone option:selected').val();
          var car_type=$('#car_type option:selected').val();
          showhideprice(parking_zone, car_type);

        })

        $('#car_type').on('change',function(){
          var parking_zone=$('#zone option:selected').val();
          var car_type=$('#car_type option:selected').val();
          showhideprice(parking_zone, car_type);
        })

        $('#parking-zone').on('change',function(){

          var parking_zone=$('#parking-zone option:selected').val();
          var car_type=$('parking zone category').val();
          showhideprice(parking_zone, car_type);
        })


//	back to form
        $('.back-toform').on('click',function(){
          $('.details-confirm').addClass('fadeOutRight');
          $('.details-confirm').addClass('d-none');
          $('.the-transaction-form').removeClass('d-none');
          $('.the-transaction-form').addClass('fadeInLeft');
          $('.the-transaction-form').removeClass('fadeOutLeft');
        });

        $('.transaction-form-btn').on('click',function(){
          $('.details-confirm').removeClass('fadeOutRight');
          $('.details-confirm').removeClass('d-none');
          $('.the-transaction-form').removeClass('fadeInLeft');
          $('.the-transaction-form').addClass('fadeOutLeft');
          $('.the-transaction-form').addClass('d-none');
          $('.details-confirm').addClass('fadeInRight');

        });

        $('.confirm-btn').on('click', function(){
          $('.btn-txt').addClass('d-none');
          $('.lds-ellipsis').removeClass('d-none');




          setTimeout(popprice, 2200);
          setTimeout(showloader, 4200);

        });

        function popprice(){
          $('#pay-confirmation-pop').removeClass('d-none');
        }

        function showloader(){
          $('.lds-roller').addClass('fadeOut');
          $('.lds-roller').addClass('position-absolute');
          $('.confirmed-pay').addClass('fadeIn');
          $('.confirmed-pay').removeClass('d-none');
          $('.transacton-instructions').addClass('d-none');
          $('.transactions-actions').addClass('fadeIn');
          $('.transactions-actions').removeClass('d-none');

          $('.payment-status').text('Received!');
        }

//	delete pop functions
        $('.cars-container').on('click','.remove-car', function () {
          $('.notification-container').addClass('fadeIn');

          $('.notification-container').removeClass('d-none');

          $('.notification-container').removeClass('fadeOut');
          $('.notification-container').removeClass('d-none');
          $('.notification-container .card').addClass('bounceUp');
          var title="lamba;";
          var title=$(this).siblings().text();
          $('#record-name').text(title);

          console.log(title);
        });

        $('.transactions-details-container').on('click','.remove-car', function () {
          $('.notification-container').addClass('fadeIn');
          $('.notification-container').removeClass('d-none');
          $('.notification-container').removeClass('fadeOut');
          $('.notification-container').removeClass('d-none');
          $('.notification-container .card').addClass('bounceUp');
          var title="lamba;";
          var title=$(this).parent().siblings().children("strong").text();
          $('#record-name').text(title);

          console.log(title);
        });

        $('.close-delete').on('click', function(){
          closedeletealert();
        });


        function closedeletealert() {
          $('.notification-container').addClass('fadeOut');
          $('.notification-container').addClass('d-none');
          $('.notification-container .card').addClass('fadeOutDown');

          setTimeout(function () {
            $('.notification-container').removeClass('fadeOut');
            $('.notification-container .card').removeClass('fadeOutDown');
          }, 1000);
        }

//	sign up form animations
        $('.toogle-loginfforms li').on('click', function(){
          $(this).addClass('active-log').siblings().removeClass('active-log');
        })

        function showsignup(){
          $('#signup-form').removeClass('d-none');
          $('#signup-form').removeClass('bounceOutLeft');
          $('#signup-form').addClass('bounceInRight');
          $('#login-form').addClass('d-none');
          $('#login-form').addClass('bounceOutLeft');

        }

        function showlogin(){
          $('#login-form').removeClass('d-none');
          $('#login-form').removeClass('bounceOutLeft');
          $('#login-form').addClass('bounceInRight');
          $('#signup-form').addClass('d-none');
          $('#signup-form').addClass('bounceOutLeft');

        }

        $('.show-signup').on('click', function(){
          showsignup();
        });

        $('.show-login').on('click', function(){
          showlogin();
        });


        $('.profile-nav .nav-item a, .transactions-link').on('click', function(){
          $('.my-profile-linker').addClass('d-none');
          $('.my-profile-linker').removeClass('fadeIn');
          $(this).addClass('active').parent().siblings().children().removeClass('active');
          $('.profile-loader').removeClass('d-none');
          $('.profile-loader').addClass('fadeIn');
          $('.txt-loader').removeClass('d-none');
          $('.account-txt-block p,.account-txt-block h4').addClass('d-none');
          $('.account-txt-block').addClass('mb-5');
          $('#transactions-minor').removeClass('d-none');
          $('#transactions-main').removeClass('fadeIn');
          $('#transactions-main').addClass('d-none');
          setTimeout(function () {
            $('.profile-loader').addClass('d-none');
            $('.profile-loader').removeClass('col-md-12,col-lg-12');
            $('.profile-loader').removeClass('fadeIn');
            $('.account-txt-block p,.account-txt-block h4').removeClass('d-none');
            $('.txt-loader').addClass('d-none');
            $('.account-txt-block').removeClass('mb-5');
          }, 1800);





        });

        $('.account-link').on('click', function(){
          $('.account-txt-block h4').text('My account')
          $('.account-txt-block p').text('Below Are Etails On Your Profile. Do Remember To Save Your Work Once You Have Edited An Item In Your Profile')

          setTimeout(function () {
            $('.my-profile-linker').addClass('d-none');
            $('#my-account').addClass('fadeIn');
            $('#my-account').removeClass('d-none');


          }, 1801);
        });

        $('.assets-link').on('click', function(){
          $('.account-txt-block h4').text('My Assets')
          $('.account-txt-block p').text('This Are Some Of The Assets Registered Under Your Account')

          setTimeout(function () {
            $('.my-profile-linker').addClass('d-none');
            $('#the-assets').addClass('fadeIn');
            $('#the-assets').removeClass('d-none');

          }, 1801);
        });


        $('.bills-link').on('click', function(){
          $('.account-txt-block h4').text('My bills')
          $('.account-txt-block p').text('bellow are a number of bills that were raised for you at the county')

          setTimeout(function () {
            $('.my-profile-linker').addClass('d-none');
            $('#my-bills').addClass('fadeIn');
            $('#my-bills').removeClass('d-none');

          }, 1801);
        });


        $('.year-list li').on('click',function(){
          $(this).addClass('checked').siblings().removeClass('checked');
        })


        $('.transactions-link').on('click', function(){
          $('.account-txt-block h4').text('My transactions');
          $('.account-txt-block p').text('bellow are a number of transactions that you have done within the county');
          $('.profile-loader').addClass('col-md-12,col-lg-12');
          $('#transactions-minor').addClass('d-none');



          setTimeout(function () {

            $('#transactions-main').addClass('fadeIn');
            $('#transactions-main').removeClass('d-none');

            $('.my-profile-linker').addClass('d-none');
            $('.my-profile-linker').removeClass('fadeIn');

          }, 1801);


//		create business form changeing


        });

//	data tables
        $(document).ready(function() {
          $('#example').DataTable( {

            "ordering": false,
            pageLength: 5,

          } );


        } );
        $(document).ready(function() {
          $('#bill-table').DataTable( {

            "ordering": false,
            pageLength: 5,

          } );


        } );
//	data tables

        $('#carouselExampleSlidesOnly').carousel();

      })(jQuery);

//this part affects the transactions page

    },
    getBill(){
      this.message = null;
      const data = new FormData();
      data.append("function", "getBill");
      data.append("billNo", this.billNo);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.billResult = true
              this.billDetails = res.data.data.billDetails
              this.billInfo = res.data.data.billInfo
              this.amountToPay = this.billDetails.billBalance
              this.paymentData.amount = res.data.data.billDetails.billBalance
              this.paymentData.accNo = res.data.data.billDetails.billNo
              this.bank = res.data.data.bank
              this.paymentData.payBillNo = this.bank.payBill

            }else{
              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    capitalizeText(text) {

      if(text == null){
        // Convert the text to lowercase and then capitalize the first letter
        return "";
      }else {
        // Convert the text to lowercase and then capitalize the first letter
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
      }


    },
    getCounty(){
      this.incomeTypes.splice(0)
      const data = new FormData();
      data.append("function", "getCounty");
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.county = res.data.data.county
            }else{
              // alert(res.data.message)
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    getFeesAndCharges(){
      this.feesAndCharges.splice(0)
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId", this.incomeType.incomeTypeId);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.feesAndCharges = res.data.data.feesAndCharges
            }else{
              alert(res.data.message)
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    customerPayBillOnline() {

      this.loading = true
      this.payConfirmationPop = true;
      this.loadingPayment = true;

      const data = new FormData();
      data.append("function", "customerPayBillOnline");
      data.append("payBillNumber", this.bank.payBill);
      data.append("amount", this.amountToPay);
      data.append("accountReference", this.billDetails.billNo);
      data.append("transactionDesc", this.billDetails.billNo);
      data.append("phoneNumber", this.phoneNumber);
      data.append("token", "im05WXYH2rwRruPjCICieOs8m4E8IoltnDEhyPUv6bnB9cU60gD48SnJPC6oh7EpsPaAUGC8wqIdtVVjGlWLxqFssshxMHxHjEQJ");
      execute2(data, paysol)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              // Call checkPayment every 2 seconds until it returns true
              const intervalId = setInterval(() => {
                this.checkPayment()
                    .then((result) => {
                      if (result) {
                        // If the condition is true, clear the interval
                        clearInterval(intervalId);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
              }, 2000);
            } else {
              alert(res.data.message);
            }
          })
          .catch((e) => {
            // Handle error
            this.message = e.message;
          });
    },
    checkPayment() {
      this.loadingPayment = true;
      const data = new FormData();
      data.append("function", "checkPayment");
      data.append("accountRef", this.billDetails.billNo);

      return new Promise((resolve, reject) => {
        execute(data, biller)
            .then((res) => {
              if (res.data.success) {
                this.loadingPayment = false;
                this.transaction = res.data.data.transaction;
                this.receiptDetails = res.data.data.receipt.receiptDetails
                this.paymentReceived = true
                resolve(true);

                /* if (res.data.data.Push.callback_returned === "PAID") {
                     this.loadingPayment = false;
                     this.transaction = res.data.data.transaction;
                     this.receiptDetails = res.data.data.receipt.receiptDetails
                     this.paymentReceived = true
                     resolve(true);
                 }
                 else {
                     resolve(false);
                 }*/
              } else {
                resolve(false);
                // reject(new Error(res.data.message));
              }
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    printReceipt() {
      if (this.receiptDetails.receiptNo == null) {
        alert("Try again later");
      } else {
        //  this.$refs.closeReceived.click();
        localStorage['params'] = JSON.stringify({
          receiptNo: this.receiptDetails.receiptNo
        })
        const routeData = this.$router.resolve({name: 'receipt'});
        window.open(routeData.href, '_blank');
      }
    }

  }
}
</script>


<style scoped>
.warning-message {
  background-color: #f2dede; /* Light red background */
  color: #a94442; /* Dark red text color */
  padding: 10px;
  border: 1px solid #ebccd1; /* Border color */
  border-radius: 5px; /* Rounded corners */
  margin-bottom: 10px; /* Space below the message */
}

</style>