<template>

    <html>
    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta name="author" content="Theme Region">
        <meta name="description" content="">
        <title>Renew Business  Permit</title>
        <!-- Bootstrap CSS -->
        <link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet">
        <link href="css/animate/animate.min.css" rel="stylesheet">
        <link rel="stylesheet" href="css/bootstrap.css">
        <!--	bootstrap css-->
        <!--  CSS -->
        <link rel="stylesheet" href="css/navigation-temp.css">
        <link rel="stylesheet" href="css/style.css">
        <!-- <link rel="stylesheet" href="css/style2.css"> -->

        <link rel="stylesheet" href="css/form.css">
    </head>

    <body>
    <!--	!--	MENU CONTROLS-->

    <!-- header -->
    <!--		navigation bar -->

    <ToolBarMain/>


    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="search-receipt">
        <div class="container p-lg-5 p-md-0">
            <div class="row p-5">
                <div class="receipt-search-container m-5 d-flex flex-column-md animated" id="get-receipt-container">
                    <div class="col-lg-6 receipt-img-container">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">print a receipt for a transaction</h2>
                            <p class="font-14 mb-3 text-capitalize">Make sure all inputs are correct. The process has been made this way to help protect personal data</p>
                        </div>
                    </div>
                    <div class="col-lg-6 p-5 position-relative"> <span class="close-receipt-form position-absolute"> <i data-feather="x"></i></span>
                        <form class="transaction-info">
                            <div class="">
                                <h4 class="mb-5 pb-2 text-capitalize">correctly fill in the form bellow to continue</h4>
                            </div>
                            <div class="row m-0">
                                <div  class="form-group col-sm-12 pl-md-0">
                                    <label for="sel1"><small>What was the transacton type?</small></label>
                                    <select class="form-control mb-3" id="sel1" required>
                                        <option>Parking</option>
                                        <option>Business</option>
                                        <option class="text-capitalize">County bills</option>
                                        <option class="text-capitalize">land rates</option>
                                        <option class="text-capitalize">rents</option>
                                        <option class="text-capitalize">e-Construction</option>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div class="row radio-container m-0">
                                <div class="col-12 pl-md-0 mb-3">
                                    <div class="form-check-inline">
                                        <label class="form-check-label">M-Pesa
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">NBK
                                            <input type="radio" class="form-check-input" name="method" required>
                                            <span class="checkmark"></span> </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="exampleInputEmail1" class="text-capitalize"><small>what was the refference number for the transaction?</small></label>
                                    <input type="text" class="form-control" id="fname" placeholder="eg M-Pesa ref number" required>
                                </div>
                                <div class="form-group col-sm-12 pl-md-0 mb-3">
                                    <label for="identifier" class="text-capitalize"><small>Unique identifire for transaction eg Number plate, bill number, Business ID etc</small></label>
                                    <input type="text" class="form-control" id="identifier" placeholder="identifier" required>
                                </div>
                                <button type="submit" class="btn btn-primary text-capitalize text-white font-14 submit-btn">get receipt</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--form section-->
    <!--	pay confirmation pop up-->
    <section class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated d-none" id="pay-confirmation-pop">
        <div class="container p-md-5 p-sm-0">
            <div class="row p-5 d-flex justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-5 payment-pop-container m-5 m-sm-3 d-flex flex-column-md animated p-0">

                    <div class="col-12 position-relative p-0">
                        <span class="close-receipt-form position-absolute z-index-1 d-none transactions-actions animated text-white" id="close-pricepop"> <i data-feather="x"></i></span>

                        <div class="">
                            <div class="show-amount-loader">
                                <center class="p-5 text-white">
                                    <div class="lds-roller animated"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <div class="confirmed-pay d-none animated"><img src="img/icons/transactions-icons/check.svg"></div>
                                    <p class="text-center text-white m-0 p-0 mb-3  payment-status">pending daily parking payment</p>
                                    <h2 class="mb-5 pb-5 text-capitalize text-white"><sup class="font-14 text-uppercase">Kes</sup>2,000</h2>
                                </center>
                            </div>
                            <div class="col-12 p-lg-5 p-sm-3 ">
                                <div class="col-12 p-0 transacton-instructions">
                                    <h5><strong class="text-capitalize pb-3">payment procedure</strong></h5>
                                    <p class="font-12">follow the following payment procedure in order to complete the payment</p>

                                    <hr>
                                    <ul type="1" class="font-14 pb-5">
                                        <li>1.Check on a <strong>payment pop </strong>up on your phone.</li>
                                        <li>2.Input your <strong>M-pesa pin</strong> and click OK.</li>
                                        <li>3.An <strong>MPESA</strong> confimation SMS will be sent to you.</li>
                                        <li>4.Wait for upto <strong>45 secs</strong> as we process your transaction</li>
                                    </ul>
                                </div>


                                <div class="col-12 p-0 pb-3 d-none transactions-actions animated mt-5">
                                    <h5><strong class="text-capitalize pb-3">Thank You!</strong></h5>
                                    <p class="font-14 text-capitalize m-0">Payment was succesfully received.</p>
                                    <p class="font-14">you can close the pop up or perform the actions bellow</p>
                                    <a href="#" class="btn btn-primary px-5 py-3 text-white text-uppercase font-12 font-sm-10 px-sm-4">Print Receipts</a>
                                    <a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase font-12 font-sm-10 px-sm-4">add to assets</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!--	pay confirmation pop up-->
    <section id="service-form-section" class="">
        <div class="container">
            <div class="row p-5 ">
                <div class="service-form-container  d-flex flex-column-md animated col-12 p-0">
                    <div class="col-lg-7 service-form-img-container" id="biz-img">
                        <div class="col-lg-8 col-md-12 position-relative p-5">
                            <h2 class="mb-4 text-capitalize text-white">Business Permit</h2>
                            <p class="font-14 mb-3 text-capitalize">fill in and remember to double check details to avoid any errors.</p>
                        </div>
                    </div>
                    <div class="col-lg-5 p-5 position-relative transactions-form-container d-flex">



                        <div class="col-12  p-0 animated details-confirm">
                            <div class="">
                                <p class="mb-5 pb-2 text-capitalize">
                                    <span class="back-toform" title="back to transactions form"><i data-feather="arrow-left"  class="mr-3 float-left"></i></span><strong>confirm your transaction details</strong>
                                </p>
                                <hr class="mt-1 p-0">
                                <p><strong class="text-capitalize">buiness details</strong></p>

                                <div class="transactions-details-container text-capitalize">
                                    <p class="m-0"><span>business name</span></p>
                                    <strong>{{business.businessName}}</strong>
                                    <hr>
                                    <p class="m-0"><span>business ID</span></p>
                                    <strong>{{business.businessID}}</strong>
                                    <hr>

                                   <!-- <p class="m-0"> <span>Business activities</span></p>
                                    <b>{{business.businessSubCategory}}</b>
                                    <hr>-->

                                 <!--   <p class="m-0"> <span>last renewal date</span></p>-->
                                  <!--  <strong class="text-uppercase">{{business.lastRenewed}}</strong>-->
                                    <hr>
                                    <div class=" text-right amount-container">
                                        <p :key="index" :value="item" v-for="(item, index) in feesAndCharges">
                                            <span class="text-left">{{item.feeDescription}}</span> <strong> KES {{numberFormat(item.unitFeeAmount)}}</strong>
                                        </p>
                                    </div>
                                    <hr>

                                    Total Amount <strong>KES {{numberFormat(totalAmount)}}</strong>

                                    <form>

                                        <div class="form-group col-sm-12 col-md-12 p-0">
                                            <label for="number-plate" class="text-capitalize">amount to pay</label>
                                            <input v-model="totalAmount" type="text" class="form-control" id="amount" placeholder="Enter amount to pay" readonly>

                                        </div>

                                        <div class="form-group col-sm-12 col-md-12 p-0">
                                            <label for="mpesa-number" class="text-capitalize">M-pesa phone number</label>
                                            <input v-model="phoneNumber" type="text" class="form-control" id="mpesa-number" placeholder="eg 0712...">
                                        </div>

                                        <div class="col-12 p-0 text-uppercase mt-5">
                                            <button type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0 confirm-btn">
                                                <div class="btn-txt animated">
                                                    <span @click="generateBill()" class="btn-text text-uppercase font-12">pay now kes {{numberFormat(totalAmount)}}</span>
                                                    <i data-feather="arrow-right" class="ml-2 float-right pull-right">
                                                    </i>
                                                </div>
                                                <div v-if="loading" class="lds-ellipsis animated"><div></div><div></div><div></div><div></div></div>
                                            </button>
                                        </div>

                                        <div class="col-12 p-0 text-uppercase mt-5">
                                            <button type="button" class="btn btn-primary text-white font-14 w-100 p-4 center mx-0 confirm-btn">
                                                <div class="btn-txt animated">
                                                    <span @click="generateBill()" class="btn-text text-uppercase font-12">PRINT INVOICE kes {{numberFormat(totalAmount)}}</span>
                                                    <i data-feather="arrow-right" class="ml-2 float-right pull-right">
                                                    </i>
                                                </div>
                                                <div v-if="loading" class="lds-ellipsis animated"><div></div><div></div><div></div><div></div></div>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!--	pay confirmation pop up-->
    <section v-if="payConfirmationPop" class="account-pop-up h-100vh w-100 justify-content-center align-items-center animated">
        <div class="container p-md-5 p-sm-0">
            <div class="row p-5 d-flex justify-content-center">
                <div class="col-sm-12 col-md-6 col-lg-5 payment-pop-container m-5 m-sm-3 d-flex flex-column-md animated p-0">

                    <div class="col-12 position-relative p-0">
                        <span class="close-receipt-form position-absolute z-index-1 d-none transactions-actions animated text-white" id="close-pricepop"> <i data-feather="x"></i></span>

                        <div class="">
                            <div class="show-amount-loader">
                                <center class="p-5 text-white">
                                    <div v-if="loadingPayment" class="lds-roller animated"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                    <div v-if="paymentReceived" class="confirmed-pay animated"><img src="img/icons/transactions-icons/check.svg"></div>
                                    <p v-if="!paymentReceived" class="text-center text-white m-0 p-0 mb-3  payment-status">pending payment</p>
                                    <h2 class="mb-5 pb-5 text-capitalize text-white"><sup class="font-14 text-uppercase">Kes</sup>{{numberFormat(totalAmount)}}</h2>
                                </center>
                            </div>
                            <div class="col-12 p-lg-5 p-sm-3 ">
                                <div v-if="!paymentReceived" class="col-12 p-0 transacton-instructions">
                                    <h5><strong class="text-capitalize pb-3">payment procedure</strong></h5>
                                    <p class="font-12">follow the following payment procedure in order to complete the payment</p>

                                    <hr>
                                    <ul type="1" class="font-14 pb-5">
                                        <li>1.Check on a <strong>payment pop </strong>up on your phone.</li>
                                        <li>2.Input your <strong>M-pesa pin</strong> and click OK.</li>
                                        <li>3.An <strong>MPESA</strong> confimation SMS will be sent to you.</li>
                                        <li>4.Wait for upto <strong>45 secs</strong> as we process your transaction</li>
                                    </ul>
                                </div>

                                <div v-if="paymentReceived" class="col-12 p-0 pb-3 transactions-actions animated mt-5">
                                    <h5><strong class="text-capitalize pb-3">Thank You {{transaction.names}}!</strong></h5>
                                    <p class="font-14 text-capitalize m-0">Payment was successfully received. <strong>{{transaction.transaction_code}}</strong></p>
                                    <p class="font-14">you can close the pop up or perform the actions bellow</p>
                                    <a @click="printReceipt()" href="javascript: void(0);" class="btn btn-primary px-5 py-3 text-white text-uppercase font-12 font-sm-10 px-sm-4">Print Receipts</a>
                                    <!--<a href="#" class="btn btn-secondary px-5 py-3 text-black text-uppercase font-12 font-sm-10 px-sm-4">add to assets</a>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--	pay confirmation pop up-->

    <!--			footer-->

    <FooterBar/>

    <!--			footer-->


    </body>
    </html>

</template>

<script>
    import {biller, execute, execute2, paysol, trade} from "@/api";
    import ToolBarMain from "@/components/ToolBar.vue";
    import FooterBar from "@/components/Footer.vue";
    export default {
        name: "BusinessPayment",
      components: {FooterBar, ToolBarMain},
        data() {
            return {
                phoneNumber:'',
                payConfirmationPop: false,
                loading: false,
                loadingPayment: false,
                paymentReceived: false,
                billGenerated: {
                    billNo: null,
                    payBillNo: null,
                    amount: null,
                    success: null
                },

                date: null,
                receiptDetails: {
                    id: null,
                    receiptNo: null,
                    billNo: null,
                    detailAmount: null,
                    receiptAmount: null,
                    billBalance: null,
                    costCenterNo: null,
                    accountNo: null,
                    incomeTypeDescription: null,
                    feeID: null,
                    wardID: null,
                    subCountyID: null,
                    currency: null,
                    source: null,
                    transactionCode: null,
                    paidBy: null,
                    dateCreated: null,
                    dateModified: null,
                    createdBy: null,
                    modifiedBy: null,
                    isActive: null,
                    status: null,
                },
                link: '',
                receiptInfos: [{
                    id: null,
                    receiptNo: null,
                    billNo: null,
                    feeID: null,
                    feeDescription: null,
                    billTotal: null,
                    receiptAmount: null,
                    billBalance: null,
                    customer: null,
                    clientPhoneNo: null,
                    description: null,
                    receiptDate: null,
                    printCount: null,
                    wardID: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardName: null,
                    dateCreated: null,
                    dateModified: null,
                    createdBy: null,
                    modifiedBy: null,
                    printedBy: null,
                    updated: null,
                    isActive: null,
                    status: null,
                    zone: null,
                    departmentID: null,
                    department: null,
                    idNo: null,
                    phoneNumber: null,
                    names: null,
                    customerPhoneNumber: null,
                    category: null,
                    incomeTypePrefix: null
                }],
                county: {
                    id: null,
                    countyID: null,
                    countyName: null,
                    tagline: null,
                    smsusername: null,
                    smspassword: null,
                    contact: null,
                    center: null,
                    address: null,
                    email: null,
                    headline: null,
                    tagline2: null,
                    ussd: null,
                    logo: null,
                    bank: null,
                    powered: null,
                    stateLogo: null,
                    seal: null,
                    signature: null,
                    lat: null,
                    lng: null,
                    link: null,
                    paysol: null,
                    poweredByLogo: null,
                    mobileApp: null,
                    selfService: null
                },
                business: {
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    plotNumber: null,
                    physicalAddress: null,
                    buildingName: null,
                    buildingOccupancy: null,
                    floorNo: null,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    feeID: null,
                    businessEmail: null,
                    postalAddress: null,
                    postalCode: null,
                    businessPhone: null,
                    contactPersonNames: null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames: null,
                    ownerID: null,
                    ownerPhone: null,
                    ownerEmail: null,
                    kraPin: null,
                    createdBy: null,
                    createdByIDNo: null,
                    dateCreated: null,
                    lat: null,
                    lng: null,
                    lastRenewed: null,
                    permitsCount: null,
                    conservancy: null,
                    fireLicence: null,
                    liquorLicence: null,
                    businessType: null
                },
                feesAndChargeItems:[{
                    feeId: null,
                    feeDescription: null,
                    unitOfMeasure: null,
                    unitFeeAmount: null,
                    accountNo: null,
                    incomeTypeId: null,
                    feeType: null,
                    accountDesc:null ,
                    costCenterNo:null,
                    zone: null,
                    typeDescription: null,
                    customer: null
                }],
                feesAndCharges:[
                    {
                        feeId: null,
                        feeDescription: null,
                        unitOfMeasure: null,
                        unitFeeAmount: null,
                        accountNo: null,
                        incomeTypeId: null,
                        feeType: null,
                        accountDesc: null,
                        costCenterNo: null,
                        zone: null,
                        typeDescription: null,
                        prorated: null,
                        duration: null,
                        brimsCode: null
                    }

                ],
                subCounty:{},
                ward:{},
                billItem:[],
                totalAmount:0,
                transaction: {
                    id: null,
                    account_to: null,
                    account_from: null,
                    amount: null,
                    ref: null,
                    transaction_code: null,
                    payment_mode: null,
                    company: null,
                    status: null,
                    merchant_request_id: null,
                    date: null,
                    balance: null,
                    names: null,
                    email: null,
                    posted: null,
                    postedToEric: null,
                    TransactionDesc: null,
                    zone: null,
                    street: null,
                    user_id: null,
                    username: null,
                    user_full_name: null,
                    msisdn: null,
                    postedToBiller: null,
                    queried: null,
                    postedToHealth: null,
                    currency: null,
                    message: null,
                    response: null,
                    remarks: null
                },



            };
        },
        mounted() {

            let params = {}
            try {
                params = JSON.parse(localStorage['params'])
                this.getBusiness(params.businessID);
            } catch (error) {
                // ignore
            }

          /*  this.getBusiness('4218')*/

        },
        methods: {
            showDate() {
                const dateObj = new Date();
                const currentDate =
                    dateObj.getDate() +
                    "/" +
                    dateObj.getMonth() +
                    "/" +
                    dateObj.getFullYear();
                return currentDate;
            },
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            getReceipt(receiptNo) {
                const data = new FormData();
                data.append("function", "getReceipt");
                data.append("receiptNo", receiptNo);


                execute(data, biller)
                    .then((res) => {
                        if (res.data.success) {
                            this.receiptDetails = res.data.data.receiptDetails;
                            this.receiptInfos = res.data.data.receiptInfos;
                            this.county = res.data.data.county;
                            this.link = "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" + this.receiptDetails.receiptNo;
                        } else {
                            alert(res.data.data.message);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
            getBusiness(businessID){
                const data = new FormData();
                data.append("function", "getBusiness");
                data.append("businessID", businessID);
                execute(data,trade)
                    .then((res) =>{
                        if (res.data.success) {

                            this.business = res.data.data.business
                            this.feesAndCharges = res.data.data.feesAndCharges

                            this.subCounty.subCountyID = this.business.subCountyID
                            this.subCounty.subCountyName =  this.business.subCountyName
                            this.ward.wardID =  this.business.wardID
                            this.ward.wardName = this.business.wardName

                            this.feesAndChargeItems.splice(0)
                            //Set fee and charge
                            this.feesAndChargeItems = res.data.data.feesAndCharges

                            this.feesAndChargeItems = this.feesAndChargeItems.map(item => {
                                if (item.feeId === this.business.feeID) {
                                    item.customer = "Business ID: " + this.business.businessID;
                                }else {
                                    item.customer =""
                                }
                                return item;
                            });

                            this.addToBill(this.business.businessName)


                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            addToBill(revenueStreamItem){


                for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer} of this.feesAndChargeItems) {
                    this.totalAmount += parseFloat(unitFeeAmount);

                    this.billItem.push({
                        fiscalYear: this.fiscalYear,
                        typeDescription,
                        feeDescription,
                        unitFeeAmount: unitFeeAmount,
                        accountNo,
                        costCenterNo,
                        incomeTypeId,
                        revenueStreamItem: revenueStreamItem,
                        feeId,
                        amount:  unitFeeAmount,
                        customer: customer,
                        zone: sessionStorage.getItem("zone"),
                        subCountyID:this.subCounty.subCountyID,
                        subCountyName:this.subCounty.subCountyName,
                        wardID: this.ward.wardID,
                        wardName: this.ward.wardName,
                        idNo: sessionStorage.getItem("idNo"),
                        phoneNumber: sessionStorage.getItem("phoneNumber"),
                        names: sessionStorage.getItem("names"),
                        customerPhoneNumber: this.phoneNumber,
                        description: this.description,
                    });
                }
            },
            generateBill(){
                this.loading = true
                const data = new FormData();
                data.append("function", "generateBill3");
                data.append("billItem", JSON.stringify(this.billItem));
                execute(data,biller)
                    .then((res) =>{
                        this.loading = false
                        this.message =res.data.message
                        if (res.data.success) {
                            this.billGenerated = res.data.data.billGenerated
                            this.customerPayBillOnline()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            customerPayBillOnline() {
                this.payConfirmationPop = true;
                this.loadingPayment = true;

                const data = new FormData();
                data.append("function", "customerPayBillOnline");
                data.append("payBillNumber", this.billGenerated.payBillNo);
                data.append("amount", "1");
                data.append("accountReference", this.billGenerated.billNo);
                data.append("transactionDesc", this.billGenerated.billNo);
                data.append("phoneNumber", this.phoneNumber);
                data.append("token", "im05WXYH2rwRruPjCICieOs8m4E8IoltnDEhyPUv6bnB9cU60gD48SnJPC6oh7EpsPaAUGC8wqIdtVVjGlWLxqFssshxMHxHjEQJ");
                execute2(data, paysol)
                    .then((res) => {
                        if (res.data.success) {
                            // Call checkPayment every 2 seconds until it returns true
                            const intervalId = setInterval(() => {
                                this.checkPayment()
                                    .then((result) => {
                                        if (result) {
                                            // If the condition is true, clear the interval
                                            clearInterval(intervalId);
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(error);
                                    });
                            }, 2000);
                        } else {
                            alert(res.data.message);
                        }
                    })
                    .catch((e) => {
                        // Handle error
                        this.message = e.message;
                    });
            },
            checkPayment() {
                this.loadingPayment = true;
                const data = new FormData();
                data.append("function", "checkPayment");
                data.append("accountRef", this.billGenerated.billNo);

                return new Promise((resolve, reject) => {
                    execute(data, biller)
                        .then((res) => {
                            if (res.data.success) {
                                this.loadingPayment = false;
                                this.transaction = res.data.data.transaction;
                                this.receiptDetails = res.data.data.receipt.receiptDetails
                                this.paymentReceived = true
                                resolve(true);

                            } else {
                                resolve(false);
                                // reject(new Error(res.data.message));
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },
            printReceipt() {
                if (this.receiptDetails.receiptNo == null) {
                    alert("Try again later");
                } else {
                    //  this.$refs.closeReceived.click();
                    localStorage['params'] = JSON.stringify({
                        receiptNo: this.receiptDetails.receiptNo
                    })
                    const routeData = this.$router.resolve({name: 'receipt'});
                    window.open(routeData.href, '_blank');
                }
            },
            gotTo(route){
                sessionStorage.setItem("selectedSub",route)
            }
        },
    };
</script>

<style scoped>

</style>